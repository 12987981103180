import { Form } from 'antd';
import { localeKeyToString } from 'pages/utils';
import { accountLogin } from 'services/websocket/auth';
import { useContext, useState, useEffect, useReducer } from 'react';
import { getCustomRedirect, getRSAKey, encryptRSA } from 'services/auth';
import ConfigContext from 'store/ConfigContext';
import LoginMenuContext from 'store/LoginMenuContext';
import { startSessionTimer } from 'services/sessionTimer';
import ThemeContext from 'store/ThemeContext';
import LocaleContext from 'locales';

/**
 * 로그인 페이지 hook
 * 로그인 페이지의 기본 UI는 커스텀 영역에서 구현
 * 로그인 페이지에서 공통적으로 쓰는 함수들만 구현
 */

function loginStateReducer(loginState, { type, payload }) {
  switch (type) {
    case 'set-customUrls': {
      return { ...loginState, customUrls: payload };
    }
    case 'set-encryptKey':
      return { ...loginState, encryptKey: payload };
    case 'set-credential':
      return { ...loginState, credential: payload };
    case 'set-btnLoading':
      return { ...loginState, btnLoading: payload };
    default:
      return loginState;
  }
}

// eslint-disable-next-line import/prefer-default-export
export function useLogin(props = {}) {
  /**
   * 로그인에 필요한 props 커스텀 및 특정 항목만 props로 받음
   */
  const { setResetPwdProps, setServiceGuideLink, checked, history, location } = props;

  // states
  // setter를 넘겨줘야하는 state는 reducer로 사용 안함
  const [error, setError] = useState(undefined);

  const [loginForm] = Form.useForm();

  // contexts
  const { locale } = useContext(LocaleContext);
  const hideMenus = useContext(LoginMenuContext);
  const theme = useContext(ThemeContext);
  const config = useContext(ConfigContext);

  // 할당된 VD 목록과 현재 VD 정보
  const [loginState, loginDispatch] = useReducer(loginStateReducer, {
    customUrls: null,
    encryptKey: null,
    credential: { success: false },
    error: undefined,
    btnLoading: false,
  });

  const { customUrls, encryptKey, btnLoading, credential } = loginState;

  const { passwordReset } = config;

  // 기본적으로 로그인 페이지 호출 시 불러와야하는 항목들
  useEffect(() => {
    (async () => {
      if (setServiceGuideLink) {
        const resource = await import(`download/${theme.serviceGuide.link}`);
        setServiceGuideLink(resource.default);
      }
    })();

    getCustomRedirect().then((customUrl) =>
      loginDispatch({ type: 'set-customUrls', payload: customUrl }),
    );
    getRSAKey().then(({ data }) => loginDispatch({ type: 'set-encryptKey', payload: data.key }));

    const userId = localStorage.getItem('userId');
    const isSaveId = localStorage.getItem('saveId') === 'true';

    if (isSaveId && userId) {
      loginForm.setFieldsValue({ userId, saveId: true });
    }
  }, []);

  /**
   * 공통 로그인 로직
   * TODO 추후 로그인 커스텀이 필요하게 될 경우 함수로 전환하여 구성 필요
   */
  useEffect(() => {
    // 로그인 하기
    if (credential.success) {
      const { idpw } = credential;
      if (!config.saveId) delete idpw.saveId;
      Object.entries(idpw).forEach(([key, value]) => localStorage.setItem(key, value));
      startSessionTimer();

      sessionStorage.removeItem('logout');
      history.push({
        pathname: '/auth/requiredApps',
        redirectUrlToVd: location?.redirectUrlToVd,
      });
    }
  }, [credential]);

  /**
   * 커스텀 Url 열기
   * @param {*} url 대상 url
   * @return window.open
   */
  const openCustomUrl = (url) => {
    const windowFeatures =
      theme.customUrls?.passwordReset?.windowFeatures ||
      `height=${window.screen.height},width=${window.screen.width},resizable=yes,fullscreen=yes`;
    window.open(url, '', windowFeatures);
  };

  /**
   * onFinish 후처리 함수
   * @param {*} res onFinish respose 데이터
   * @param {*} payload onFinish payload 데이터
   * default로 처리되는 로직은 여기에 구성
   * 커스텀이 필요할 경우 후처리 함수는 해당 커스텀 로그인 페이지에서 구성
   */
  const defaultResult = async (res, payload) => {
    // res.twoFactor.type: 'require' | 'require-secret-key' | 'unnecessary' | 'unknown' | 'error'
    if (res.result) {
      if (res.twoFactor.type === 'error' || res.twoFactor.type === 'unknown') {
        setError(locale.resource[res.twoFactor.msg]);
      } else if (res.twoFactor.type === 'require' || res.twoFactor.type === 'require-secret-key') {
        loginDispatch({
          type: 'set-credential',
          payload: {
            success: false,
            idpw: {
              userId: res.userId,
              password: payload.password1,
              username: res.username,
              autoLogin: checked,
              autoConnect: true,
              redirectComplete: false,
              // 토큰 없음
            },
            twoFactor: res.twoFactor,
          },
        });
      } else {
        loginDispatch({
          type: 'set-credential',
          payload: {
            success: true,
            idpw: {
              userId: res.userId,
              username: res.username,
              autoLogin: checked,
              autoConnect: true,
              redirectComplete: false,
              token: res.token,
              saveId: payload.saveId ?? false,
            },
          },
        });
      }
    } else if (res.ldapCode === 773 || res.ldapCode === 532) {
      setResetPwdProps({ visible: true, userId: res.userId, msg: res.msg });
    } else {
      setError(localeKeyToString(res.msg));
    }
  };

  /**
   * 로그인 submit 함수
   * @param {*} values  form values
   * @param {*} customResult 커스텀 후처리 함수
   * @description 커스텀 후처리 함수가 있을 경우 위 default 후처리 대신 사용 (ex 하나은행에서는 생체인증에 관한 후처리 존재)
   */
  const onFinishApi = async (values, customResult) => {
    loginDispatch({ type: 'set-btnLoading', payload: true });
    setError(undefined); // sumit 할때마다 error를 clear 한다.

    const password1 = encryptRSA(values.password, encryptKey);
    const payload = { ...values, autoLogin: checked, password: password1 };
    const res = await accountLogin(payload);
    if (customResult) {
      customResult(res, payload);
    } else {
      defaultResult(res, payload);
    }
    loginDispatch({ type: 'set-btnLoading', payload: false });
  };

  /**
   * 비밀번호 초기화하기
   *   방법 1. InputForResetPWD(자체): 입력받은 아이디의 이메일로 초기화 URL을 보내 처리
   *   방법 2. windowOpen(외부): 환경설정 > 기능 > Portal 내 링크 커스터마이징 > 비밀번호 초기화 url을 연다.
   * @returns ReactNode || window.open
   */
  /**
   * TODO 커스텀이 들어가지 않은 경우엔 아래 함수가 onClick 함수로 쓰이기에 첫번째 인자가 event로 들어옴
   * 커스텀의경우에는 externalUrl이 들어 오지만 동일한 형식으로 쓰기 위해서는 커스텀의 경우 두번째 인자값으로 커스텀인자를 받아야함
   */
  const doPasswordReset = async (event, customReset) => {
    let externalUrl = customUrls?.passwordReset || undefined; // 비밀번호 초기화 url
    if (customReset) {
      externalUrl = customReset;
    }
    if (passwordReset) {
      return passwordReset({ locale, customUrls });
    }

    return externalUrl ? openCustomUrl(externalUrl) : setResetPwdProps({ visible: true });
  };
  const handleLoginSuccess = (success) => {
    loginDispatch({ type: 'set-credential', payload: success });
  };
  return {
    login: {
      loginForm,
      customUrls,
      credential,
      handleLoginSuccess,
      openCustomUrl,
      onFinishApi,
    },
    ui: {
      doPasswordReset,
      btnLoading,
      hideMenus,
      theme,
    },
    settings: {
      config,
      locale,
      error,
      setError,
    },
  };
}
