import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Menu,
  Row,
  Space,
  Carousel,
  Spin,
  Skeleton,
} from 'antd';
import { Icon, Dropdown } from 'components';
import { useDesktops } from 'hooks/useDesktops';
import LocaleContext from 'locales';
import { displaySize } from 'pages/utils';
import React, { useContext, useEffect, useRef } from 'react';
import { AiFillApi, AiOutlineDesktop, AiOutlineEllipsis } from 'react-icons/ai';
import NoAvailableDesktops from './NoAvailableDesktops';
import RenderTags from './RenderTags';

/**
 * 접속 가능 Desktops: (siteCustoms.defaultDesktopViewer가 carousel인 경우)
 * @param {string} redirectUrlToVd 브라우저의 window.location값으로 URL Redirection을 위한 url
 * @param {boolean} isAutoConnect DB 환경 설정의 config.application.vsclient.auto-connect 값
 * @returns ReactNode
 */
export default function ViewSimple({ redirectUrlToVd }) {
  const { locale } = useContext(LocaleContext);
  const { state, fetchDesktops, setCurrent, onConnect, onFailover } = useDesktops();
  const { desktops, current } = state;

  const carouselRef = useRef(null);

  // 초기 렌더링 및 새로고침 시 할당된 VD 목록을 가져온다.
  useEffect(() => fetchDesktops.runAsync(), []);

  // 할당받은 VD(그룹) 목록에서 current를 찾은 후 최초 표시할 슬라이드 넘버를 세팅한다.
  useEffect(() => {
    if (current) {
      // TODO 웹소켓으로 current가 계속 변하기 때문에 목록 데이터에서 current를 못찾는 버그가 발생할 수 있다.(현재 해결책을 찾지 못함)
      const idx = desktops.findIndex((el) => el.id + el.vdId === current.id + current.vdId);
      carouselRef.current.goTo(idx);
    }
  }, [current]);

  // 슬라이드가 변경되었을 때 웹소켓 구독을 위해 current를 세팅한다. afterChange로 하면 무한 루프에 빠지기 때문에 주의
  const onBeforeChange = (currentSlide, nextSlide) => {
    setCurrent(desktops[nextSlide]);
  };

  if (!fetchDesktops.loading && desktops.length === 0) {
    return <NoAvailableDesktops locale={locale} fetchDesktops={fetchDesktops} />;
  }

  return !current ? (
    <Row gutter={[24, 0]} align="middle" style={{ flexWrap: 'nowrap' }}>
      <Col>
        <Skeleton.Image active style={{ width: '100%', height: '100%' }} />
      </Col>
    </Row>
  ) : (
    <Spin spinning={fetchDesktops.loading}>
      <Row gutter={24} align="middle" style={{ flexWrap: 'nowrap' }}>
        <Col>
          <Carousel
            dots={{ className: 'ismobile' }}
            ref={carouselRef}
            beforeChange={onBeforeChange}
            slidesToShow={1}
            slidesPerRow={1}
            swipeToSlide
          >
            {
              // map의 각 요소는 el이 맞으나 웹소켓을 받은 데이터를 실시간 업데이트하기 위해 current 데이터로 한다.
              desktops.map((el) => {
                return (
                  <Col span={24} key={el.adAccount + el.vdGroupName}>
                    <Card
                      bordered={false}
                      actions={[
                        <Button
                          block
                          type="link"
                          disabled={current.actions?.connect.disabled}
                          icon={<Icon name={<AiFillApi className="anticon" />} />}
                          onClick={() => onConnect(undefined, redirectUrlToVd, current)}
                        >
                          {locale.resource['txt.connect']}
                        </Button>,
                      ]}
                      style={{ boxShadow: '1px 1px 2px rgba(0, 42, 88, 0.16)' }}
                    >
                      <Card.Meta
                        title={
                          <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div>{current.adAccount || current.vdGroupName}</div>
                            <div>
                              <Dropdown
                                trigger={['click']}
                                overlay={() => {
                                  const menuItems = current.actions.failovers.map((item) => {
                                    return (
                                      <Menu.Item
                                        key={item.label}
                                        disabled={item.disabled}
                                        onClick={() => onFailover(item)}
                                      >
                                        {locale.resource[item.label]}
                                      </Menu.Item>
                                    );
                                  });
                                  return <Menu>{menuItems}</Menu>;
                                }}
                              >
                                <Button type="link" icon={<Icon name={<AiOutlineEllipsis />} />} />
                              </Dropdown>
                            </div>
                          </Space>
                        }
                        description={
                          <Space direction="vertical">
                            <RenderTags desktop={current} locale={locale} />
                            <Space split={<Divider type="vertical" />}>
                              <div>
                                {current.cpus}
                                {locale.resource['txt.core']}/{displaySize(current.ram).size}
                                {displaySize(current.ram).unit}/{current.size}
                                {locale.resource['txt.gb']}
                              </div>
                              <div>{current.os}</div>
                            </Space>
                          </Space>
                        }
                        avatar={<Avatar size={40} icon={<Icon name={<AiOutlineDesktop />} />} />}
                      />
                    </Card>
                  </Col>
                );
              })
            }
          </Carousel>
        </Col>
      </Row>
    </Spin>
  );
}
