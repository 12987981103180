import { Button, Menu } from 'antd';
import { Icon, Dropdown } from 'components';
import LocaleContext from 'locales';
import React, { useContext, useState } from 'react';
import { AiOutlineGlobal } from 'react-icons/ai';
import ConfigContext from 'store/ConfigContext';

/**
 * 기본값은 Local Storage의 locale이고 없으면 브라우저의 언어를 따른다.
 * navigator.language는 브라우저마다 리턴되는 값이 다른다.(특히 Edge)
 * @returns String
 */
const defaultLocale = () => {
  let lang = localStorage.getItem('locale') || navigator.language;
  if (lang === 'ko' || lang === 'ko-KR') {
    lang = 'ko-KR';
  } else {
    lang = 'en-US';
  }
  localStorage.setItem('locale', lang);
  return lang;
};

/**
 * locale을 변경하는 UI
 * @returns ReactNode
 */
function LocaleChanger() {
  const { locale, setLocale } = useContext(LocaleContext);
  const fconfig = useContext(ConfigContext);

  // state list
  const [selectedKeys, setSelectedKeys] = useState(() => [defaultLocale()]); // 선택된 Menu.Item keys

  const supportedLocale = fconfig.localeList.map((el) => ({
    label: locale.resource[el.label],
    value: el.value,
  }));
  const overlay = () => (
    <Menu
      selectedKeys={selectedKeys}
      onClick={({ key }) => {
        localStorage.setItem('locale', key);
        setSelectedKeys([key]);
        setLocale(key);
      }}
    >
      {supportedLocale.map((item) => (
        <Menu.Item key={item.value}>{item.label}</Menu.Item>
      ))}
    </Menu>
  );

  const selectedLabel = supportedLocale.find((item) => item.value === selectedKeys[0]).label;

  return (
    <Dropdown overlay={overlay}>
      <Button type="link" icon={<Icon name={<AiOutlineGlobal />} />}>
        {selectedLabel}
      </Button>
    </Dropdown>
  );
}

export default LocaleChanger;
