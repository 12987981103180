/* eslint-disable no-unused-vars */
const { sendMessage, closeSocket } = require('./webSocket');

// eslint-disable-next-line import/prefer-default-export
export function doLogout(history) {
  sendMessage({
    scope: 'portal',
    name: 'auth/logout',
    data: {
      payload: {
        userId: localStorage.getItem('userId'),
        userName: localStorage.getItem('username'),
      },
      token: localStorage.getItem('token'),
    },
  });

  localStorage.removeItem('username');
  localStorage.removeItem('autoLogin');
  localStorage.removeItem('token');
  localStorage.removeItem('password');
  localStorage.setItem('autoConnect', true);

  closeSocket();
  if (history) {
    history.push('/auth/login');
  } else {
    // XXX request()에서 HTTP-401 오류가 나는 경우 history 객체가 없으므로 다음과 같이 무식하게..
    window.location = '/portal/auth/login';
  }
}

/**
 * 터치 스크린 디바이스(모바일, 태블릿) 판별
 */
export const isTouchDevice = () => {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
};

/**
 * CSS @media screen 사이즈와 터치 스크린 여부를 기준으로 디바이스를 판별한다.
 * @param {object} breakpoint 스크린 브레이크 포인트 Grid.useBreakpoint 훅으로 가져온다.
 * @returns object
 */
export const mediaScreen = (breakpoint) => {
  const hasTouch = isTouchDevice();
  const isDesktop = breakpoint.xl === true && !hasTouch;
  const isMobile = breakpoint.xs === true && hasTouch;
  const isTablet = breakpoint.sm === true && hasTouch;

  return { isDesktop, isTablet, isMobile, breakpoint, hasTouch };
};
