/**
 * config 기본값
 * ? 여기에 설정된 값은 무조건 ConfigContext에 포함되는 값(= 사용시 optional chaining 안해도 됨)
 */

// 접속 가능 Desktops의 렌더링 유형
export const defaultDesktopViewer = 'list'; // carousel | list
export { MultiFactor, Login } from './components';

// 지원하는 Locale 목록
// TODO: 서버측 locale에 정의된 type 형태로 가져와야 한다.
export const localeList = [
  { label: 'txt.korean', value: 'ko-KR' },
  { label: 'txt.english', value: 'en-US' },
];
