import {
  Button,
  Drawer,
  Form,
  Input,
  Layout,
  Menu,
  Modal,
  Select,
  Space,
  Spin,
  Table,
  Tag,
  Typography,
} from 'antd';
import { LinkButton, Dropdown } from 'components';
import LocaleContext from 'locales';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { AiOutlineDown } from 'react-icons/ai';
import { getDocuments, changeStateOfApproval } from 'services/websocket/session';
import Detail from './Detail';

const renderTagColor = (approval) => {
  const { locale } = useContext(LocaleContext);
  let type;
  if (approval === locale.resource['approval.state.processed']) {
    type = 'success';
  } else if (approval === locale.resource['approval.state.waiting']) {
    type = 'default';
  } else if (approval === locale.resource['approval.state.processing']) {
    type = 'processing';
  } else if (approval === locale.resource['approval.state.rejected']) {
    type = 'error';
  }
  return type;
};

// const ApprovalStep = ({ approvers }) => {
//   return (
//     <Popover
//       title="결재 상황"
//       size="small"
//       content={
//         <List
//           size="small"
//           dataSource={approvers}
//           renderItem={(item) => (
//             <List.Item>
//               <Space style={{ justifyContent: 'space-between' }}>
//                 <Tag color={renderTagColor(item.approval)}>{item.approval}</Tag>
//                 <div>{item.name}</div>
//                 <div style={{ width: 140 }}>{item.date}</div>
//               </Space>
//             </List.Item>
//           )}
//         />
//       }
//     >
//       <Space size={4}>{approvers.map((item) => item.name).join(' > ')}</Space>
//     </Popover>
//   );
// };

function Request() {
  const { locale } = useContext(LocaleContext);
  const [detail, setDetail] = useState(undefined);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [tableDataSource, setTableDataSource] = useState([]);
  const [rerendering, setRerendering] = useState(true);
  const [typeFilter, setTypeFilter] = useState({});
  const [form] = Form.useForm();

  useEffect(() => {
    if (!rerendering) {
      return;
    }

    // api
    getDocuments(typeFilter).then((data) => {
      setRerendering(false);
      setTableDataSource(data);
    });
  }, [rerendering]);

  const columns = [
    {
      title: locale.resource['txt.contents'],
      dataIndex: 'type',
      render: (text, record) => (
        <LinkButton onClick={() => setDetail(record)}>{locale.resource[text]}</LinkButton>
      ),
    },
    {
      title: locale.resource['txt.status'],
      dataIndex: 'state',
      width: 180,
      render: (text) => <Tag color={renderTagColor(text)}>{locale.resource[text]}</Tag>,
    },
    {
      title: locale.resource['txt.requested-date'],
      dataIndex: 'applyDate',
      width: 180,
      render: (text) => moment(text).format('YYYY-MM-DD HH:mm:ss'),
    },
    // {
    //   title: '처리 일자',
    //   dataIndex: 'approveDate',
    //   width: 180,
    //   render: (text) => text || '처리되지 않음',
    // },
  ];

  const onSelectChange = (keys) => {
    setSelectedRowKeys(keys);
  };

  const rowSelection = { selectedRowKeys, onChange: onSelectChange };

  const approvalCancel = async () => {
    const resultArray = [];
    selectedRowKeys.forEach((key) => {
      const temp = tableDataSource.filter((data) => data.id === key);
      if (temp.length > 0) {
        resultArray.push(temp[0].id);
      }
    });

    const result = {
      ids: resultArray,
      state: 'approval.state.cancelled',
    };

    const res = await changeStateOfApproval(result);

    if (res.status === 'error') {
      Modal.error({
        title: locale.resource['txt.request-cancel-fail'],
        content: locale.resource['txt.cancel-only-waiting'],
      });
    }

    setSelectedRowKeys([]);
    setRerendering(true);
  };

  const onClose = () => {
    setRerendering(true);
    setDetail(undefined);
  };

  const RegisterMenu = () => {
    const menus = [
      'approval.types.create-vd',
      'approval.types.change-spec-vd',
      'approval.types.delete-vd',
      'approval.types.reset-vd',
      // 'Disk 신청',
      // 'Disk 용량변경 신청',
      // 'Disk 권한추가 신청',
      // 'Disk 사용기간 변경 신청',
      // 'Disk 폐기 신청',
      // '파일다운로드 신청',
      // '파일다운로드 신청(긴급)',
      // '파일다운로드 신청(대용량)',
      // '파일다운로드 신청(Tranzfile)',
      // '파일업로드 신청',
      // '파일업로드 신청(대용량)',
    ];
    return (
      <Menu>
        {menus.map((type, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Menu.Item key={index}>
            <Button type="text" onClick={() => setDetail({ type })}>
              {locale.resource[type]}
            </Button>
          </Menu.Item>
        ))}
      </Menu>
    );
  };

  return (
    <Layout.Content>
      <Spin spinning={rerendering}>
        <Table
          title={() => (
            <Space style={{ width: '100%', justifyContent: 'space-between' }}>
              <Space>
                <Dropdown overlay={RegisterMenu}>
                  <Button>
                    <span>{locale.resource['txt.request']}</span>
                    <AiOutlineDown className="anticon" />
                  </Button>
                </Dropdown>
                <Button
                  disabled={!(selectedRowKeys.length > 0)}
                  onClick={() => {
                    Modal.confirm({
                      title: locale.resource['txt.cancel-request'],
                      content: (
                        <Typography.Text type="danger">
                          {locale.resource['txt.irreversible-operation']}
                        </Typography.Text>
                      ),
                      okType: 'danger',
                      onOk: approvalCancel,
                    });
                  }}
                >
                  {locale.resource['txt.application-cancel']}
                </Button>
              </Space>
              <Input.Group compact>
                <Select
                  defaultValue=""
                  style={{ width: 170 }}
                  options={[
                    { label: locale.resource['txt.total'], value: '' },
                    {
                      label: locale.resource['approval.types.create-vd'],
                      value: 'approval.types.create-vd',
                    },
                    {
                      label: locale.resource['approval.types.change-spec-vd'],
                      value: 'approval.types.change-spec-vd',
                    },
                    {
                      label: locale.resource['approval.types.reset-vd'],
                      value: 'approval.types.reset-vd',
                    },
                    {
                      label: locale.resource['approval.types.delete-vd'],
                      value: 'approval.types.delete-vd',
                    },
                  ]}
                  onSelect={(value) => {
                    setRerendering(true);
                    setTypeFilter(value !== '' && { type: value });
                  }}
                />
              </Input.Group>
            </Space>
          )}
          size="small"
          rowKey="id"
          dataSource={tableDataSource}
          columns={columns}
          rowSelection={rowSelection}
        />
      </Spin>
      <Drawer
        title={
          <Space>
            <div>{locale.resource['txt.request-approval']}:</div>
            {locale.resource[detail?.type]}
          </Space>
        }
        visible={detail}
        onClose={() => {
          onClose();
          form.resetFields();
        }}
        width={780}
        // bodyStyle={{ maxHeight: 'calc(100vh - 308px)', overflowY: 'auto' }}
        footer={
          <Space style={{ width: '100%', justifyContent: 'flex-end' }}>
            <Button
              onClick={() => {
                onClose();
                form.resetFields();
              }}
            >
              {locale.resource['txt.close']}
            </Button>
            {detail?.id &&
              detail?.state !== 'approval.state.cancelled' &&
              detail?.state === 'approval.state.waiting' && (
                <>
                  <Button
                    onClick={async () => {
                      Modal.confirm({
                        title: locale.resource['txt.cancel-request'],
                        content: (
                          <Typography.Text type="danger">
                            {locale.resource['txt.irreversible-operation']}
                          </Typography.Text>
                        ),
                        okType: 'danger',
                        onOk: async () => {
                          selectedRowKeys.push(detail.id);
                          approvalCancel();
                          onClose();
                          form.resetFields();
                        },
                      });
                    }}
                  >
                    {locale.resource['txt.application-cancel']}
                  </Button>
                  <Button
                    type="primary"
                    onClick={async () => {
                      try {
                        await form.validateFields();
                        form.submit();
                      } catch (e) {
                        console.warn('validate fail', e);
                      }
                    }}
                  >
                    {locale.resource['txt.modify']}
                  </Button>
                </>
              )}
            {!detail?.id && (
              <Button
                type="primary"
                onClick={async () => {
                  try {
                    await form.validateFields();
                    form.submit();
                  } catch (e) {
                    console.warn('validate fail', e);
                  }
                }}
              >
                {locale.resource['txt.request']}
              </Button>
            )}
          </Space>
        }
      >
        <Detail
          approvalType={detail?.type}
          readOnly={detail?.state !== 'approval.state.waiting' && detail?.id}
          id={detail?.id}
          form={form}
          onClose={onClose}
        />
      </Drawer>
    </Layout.Content>
  );
}

export default Request;
