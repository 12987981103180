import {
  Button,
  Checkbox,
  Col,
  Descriptions,
  Form,
  Grid,
  Menu,
  Row,
  Skeleton,
  Space,
  Spin,
  Tooltip,
  Typography,
} from 'antd';
import { Icon } from 'components';
import { useDesktops } from 'hooks/useDesktops';
import LocaleContext from 'locales';
import moment from 'moment';
import { displaySize } from 'pages/utils';
import React, { useContext, useEffect } from 'react';
import { AiFillWindows, AiOutlineReload } from 'react-icons/ai';
import { mediaScreen } from 'utils';
import ConnHistoryTable from './ConnHistoryTable';
import DesktopMessage from './DesktopMessage';
import NoAvailableDesktops from './NoAvailableDesktops';
import RenderTags from './RenderTags';
import { ConnectBtn, Monitor, StyledBtn, VdMenu } from './styles';

const { useBreakpoint } = Grid;

/**
 * 접속 가능 Desktops: (siteCustoms.defaultDesktopViewer가 list인 경우)
 * @param {string} redirectUrlToVd 브라우저의 window.location값으로 URL Redirection을 위한 url
 * @param {boolean} isAutoConnect DB 환경 설정의 config.application.vsclient.auto-connect 값
 * @returns ReactNode
 */
export default function ViewList({ redirectUrlToVd, isAutoConnect, form }) {
  const { locale } = useContext(LocaleContext);
  const { state, dispatch, fetchDesktops, setCurrent, onConnect, onFailover, onAutoConnect } =
    useDesktops();
  const { desktops, current, visibleConnHistory } = state;
  const breakpoint = useBreakpoint();
  const screens = mediaScreen(breakpoint);

  // 초기 렌더링 및 새로고침 시 할당된 VD 목록을 가져온다.
  useEffect(() => fetchDesktops.runAsync(), []);

  useEffect(() => {
    if (current && isAutoConnect) {
      onAutoConnect(moment().add(3, 'm'), form.getFieldValue('multi-monitors'), redirectUrlToVd);
    }
  }, [current]);

  // 메뉴 아이템 클릭시
  const handleMenu = (info) => {
    const vd = desktops.find((item) => item.vdId + item.id === info.key);
    setCurrent(vd);
  };

  if (!fetchDesktops.loading && desktops.length === 0) {
    return <NoAvailableDesktops locale={locale} fetchDesktops={fetchDesktops} />;
  }

  return !current ? (
    <Row gutter={[20, 10]} align="top" style={{ flexWrap: 'nowrap', flexGrow: 1 }}>
      <Col flex="200px">
        <Skeleton active paragraph={{ rows: 4 }} />
      </Col>
      <Col flex="230px">
        <Skeleton active paragraph={{ rows: 6 }} />
      </Col>
      <Col flex="310px">
        <Skeleton.Image active style={{ width: 290, height: 200 }} />
      </Col>
      <Col flex="170px" style={{ alignSelf: 'flex-start' }}>
        <Space direction="vertical" size={4} style={{ display: 'flex' }}>
          <Skeleton.Button active size="large" block style={{ height: 56 }} />
          <Skeleton.Button active size="large" block style={{ height: 56 }} />
          <Skeleton.Button active size="large" block style={{ height: 56 }} />
        </Space>
      </Col>
    </Row>
  ) : (
    <Spin spinning={fetchDesktops.loading}>
      <Row gutter={[16, 0]} style={{ flexWrap: 'nowrap' }}>
        <Col flex="none">
          <Space style={{ marginBottom: 8 }}>
            <span style={{ fontSize: 14, fontWeight: 500 }}>
              {locale.resource['txt.available-desktops']}
            </span>
            <Tooltip title={locale.resource['txt.refresh']}>
              <Button
                type="text"
                icon={<AiOutlineReload className="anticon" />}
                onClick={() => fetchDesktops.runAsync()}
              />
            </Tooltip>
          </Space>
          <VdMenu selectedKeys={current.vdId + current.id}>
            {desktops.map((el) => (
              <Menu.Item key={el.vdId + el.id} onClick={handleMenu}>
                {el.vdId ? `${el.vdGroupName}(${el.adAccount})` : el.vdGroupName}
              </Menu.Item>
            ))}
          </VdMenu>
        </Col>
        <Col flex="auto">
          <Row gutter={[20, 8]} align="top" style={{ flexWrap: 'nowrap' }}>
            <Col flex="240px">
              <Typography.Title level={4} ellipsis>
                <Space direction="vertical" size={4}>
                  <RenderTags desktop={current} locale={locale} />
                  <Space align="center">
                    <AiFillWindows className="anticon" fontSize={24} />
                    <Typography.Text ellipsis style={{ width: 200 }}>
                      {current.adAccount || current.vdGroupName}
                    </Typography.Text>
                  </Space>
                </Space>
              </Typography.Title>
              <Descriptions column={1} style={{ marginTop: 16 }}>
                <Descriptions.Item label={locale.resource['txt.os']}>
                  {current.os}
                </Descriptions.Item>
                <Descriptions.Item label={locale.resource['txt.cpu']}>
                  {`${current.cpus}${locale.resource['txt.core']}`}
                </Descriptions.Item>
                <Descriptions.Item label={locale.resource['txt.memory']}>
                  {displaySize(current.ram).size + displaySize(current.ram).unit}
                </Descriptions.Item>
                <Descriptions.Item label={locale.resource['txt.disk']}>
                  {`${current.size}${locale.resource['txt.gb']}`}
                </Descriptions.Item>
                <Descriptions.Item label={locale.resource['txt.ip']}>
                  {current.ipAddress || locale.resource['txt.not-assigned-yet']}
                </Descriptions.Item>
                <Descriptions.Item label={locale.resource['txt.last-date']}>
                  <Tooltip title={locale.resource['txt.last-connection-history']}>
                    <Button
                      size="small"
                      type="link"
                      className="linkButton"
                      onClick={() => dispatch({ type: 'set-visibleConnHistory', payload: true })}
                    >
                      {current.connHistory.length
                        ? current.connHistory[0].loginDate
                        : locale.resource['txt.no-connection-history']}
                    </Button>
                  </Tooltip>
                  <ConnHistoryTable
                    visible={visibleConnHistory}
                    dispatch={dispatch}
                    dataSource={current.connHistory}
                  />
                </Descriptions.Item>
              </Descriptions>
            </Col>
            <Col>
              <Monitor screens={screens}>
                <DesktopMessage locale={locale} current={current} />
                <Tooltip title={locale.resource[current.actions.connect.tooltip]}>
                  <ConnectBtn
                    size="large"
                    disabled={current.actions.connect.disabled}
                    icon={current.actions.connect.icon}
                    onClick={() => {
                      onConnect(form.getFieldValue('multi-monitors'), redirectUrlToVd);
                    }}
                  >
                    {locale.resource[current.actions.connect.label]}
                  </ConnectBtn>
                </Tooltip>
                <Form form={form} initialValues={{ 'multi-monitors': false }}>
                  <Form.Item name="multi-monitors" valuePropName="checked" noStyle>
                    <Checkbox
                      style={{ color: '#ffffff' }}
                      disabled={!current.isAccessibleIp || current.locked}
                    >
                      {locale.resource['txt.dual-monitor']}
                    </Checkbox>
                  </Form.Item>
                </Form>
              </Monitor>
            </Col>
            <Col style={{ alignSelf: 'flex-start' }}>
              <Space direction="vertical" size={4}>
                {current.actions.failovers.map((el) => (
                  <Tooltip
                    key={el.label}
                    title={locale.resource[el.tooltip]}
                    overlayInnerStyle={{ wordBreak: 'keep-all' }}
                  >
                    <StyledBtn
                      icon={<Icon name={el.icon} />}
                      disabled={el.disabled}
                      onClick={() => onFailover(el)}
                    >
                      {locale.resource[el.label]}
                    </StyledBtn>
                  </Tooltip>
                ))}
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
    </Spin>
  );
}
