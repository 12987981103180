import React, { useContext } from 'react';
import ConfigContext from 'store/ConfigContext';

/**
 * 로그인 페이지 특성상 커스텀 개발이 많아 siteCustom 아래 커스텀페이지로 구축
 * siteCustom/{siteName}/component 아래에 Login.js 생성 후 작업
 * 로그인 페이지 커스텀이 없을시 기본 로그인페이지 사용 (경로: siteCustom/basic/component/Login.js)
 * 공통으로 쓰는 함수들은 useLogin.js에서 구현
 * 추가적인 custom은 각 siteCustom/Login.js에서 재구현해서 사용
 */

/**
 * TODO 리스트
 * 스타일은 파일로 분리 필요
 * loginForm 보다는 formData<{userId: string, saveId: boolean, ...}>로 하는 것이 나중에 확정성이 좋을 듯
 * customUrls은 라우터에서 받은 config(환경설정)
 * hideMenus, theme도 siteCustoms로 이동
 * openCustomUrl은 config+siteCustoms으로 처리하면 페이지에서 처리 가능 할 듯
 * basic.login
 * state(downloadModalVisible, serviceGuideLink, checked)가 필요한가?
 */
function Login({ history, location }) {
  const config = useContext(ConfigContext);
  return <config.Login history={history} location={location} />;
}

export default Login;
