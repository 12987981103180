import {
  Avatar,
  Badge,
  Button,
  Card,
  Carousel,
  Checkbox,
  Col,
  Descriptions,
  Divider,
  Grid,
  Menu,
  Modal,
  notification,
  Result,
  Row,
  Space,
  Spin,
  Table,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import { Icon, Dropdown } from 'components';
import LocaleContext from 'locales';
import _ from 'lodash';
import moment from 'moment';
import { ClientUpdateCheck } from 'pages';
import {
  connectByVdStart,
  displaySize,
  getStateIconInfo,
  getXauthFromCookie,
  isWindows,
  startAgent,
  vsClagentPortCheck,
} from 'pages/utils';
import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  AiFillApi,
  AiFillWindows,
  AiOutlineBars,
  AiOutlineDesktop,
  AiOutlineEllipsis,
  AiOutlineLeft,
  AiOutlinePoweroff,
  AiOutlineQuestion,
  AiOutlineRedo,
  AiOutlineReload,
  AiOutlineRight,
  AiOutlineSync,
  AiOutlineWarning,
} from 'react-icons/ai';
import { TiSpanner } from 'react-icons/ti';
import { getCurrentConfig } from 'services/mgmt';
import * as VsclientService from 'services/vsclient';
import { accountLogout, checkAccount, getConnHistory } from 'services/websocket/auth';
import {
  getAvailableVdDetail,
  getAvailableVdGroups,
  requestVdAction,
} from 'services/websocket/mgmt';
import {
  assignVd,
  getCustomClient,
  getCustomRedirect,
  unassignVds,
} from 'services/websocket/session';
import ConfigContext from 'store/ConfigContext';
import ThemeContext from 'store/ThemeContext';
import styled from 'styled-components';
import { mediaScreen } from 'utils';
import { listenMessage, sendMessage } from 'utils/webSocket';
import packageJson from '../../../../package.json';
import monitor2 from '../../../images/monitor2.png';

const StyledResult = styled(Result)`
  padding: 32px;
  height: 220px;
  .ant-result-icon {
    margin-bottom: 8px;
  }
`;

const VdMenu = styled(Menu)`
  &.ant-menu,
  .ant-menu-sub,
  &.ant-menu .ant-menu-sub {
    width: 200px;
    height: 200px;
    overflow-y: auto;
    background-color: #ecf1f6;
  }
`;

const VdInfo = styled.div`
  .monitor {
    position: relative;
    padding-top: ${(props) => (props.screens.isTablet ? '0px' : '32px')};
    width: ${(props) => (props.screens.isTablet ? '260px' : '326px')};
    height: ${(props) => (props.screens.isTablet ? '215px' : '270px')};
    background-image: url(${monitor2});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
  .failoverBtn {
    position: absolute;
    right: 0;
    bottom: 0;
  }
`;

const StyledCarousel = styled(Carousel)`
  width: 700px;
`;

const StyledBtn = styled(Button)`
  width: 156px;
  height: 56px;
  text-align: left;
  padding: 3px 24px;
  .anticon {
    font-size: 1.4em;
    vertical-align: middle;
  }
`;

const ConnectBtn = styled(Button)`
  display: inline-flex;
  padding: 8px 40px;
  height: auto;
  border-color: rgba(255, 255, 255, 0.08);
  background-color: rgba(255, 255, 255, 0.16);
  color: #ffffff;
  font-weight: 500;
  &:hover,
  &:focus {
    color: #1890ff;
    border-color: #ffffff;
    background-color: #ffffff;
    box-shadow: 0 1.6px 3.6px 0 rgb(0 0 0 / 13%), 0 0.3px 0.9px 0 rgb(0 0 0 / 11%);
  }
  /* TODO: Styled-component의 props 선언하는 방법으로 변경 + CSS 명시도 문제 해결 필요  */
  ${(props) =>
    props.disabled &&
    `background-color: rgba(0, 0, 0, 0.16) !important;
    border-color: rgba(0, 0, 0, 0.32) !important;
    color: #ffffff !important;
    opacity: 0.6 !important;
    `}
`;

function RenderTags({ vdGroup, locale }) {
  // state와 subState가 다르면 같이 표시해준다.
  const stateStr = () => {
    const state = locale.resource[vdGroup.state];
    const subState = locale.resource[vdGroup.subState];
    const result = subState && state !== subState ? `${state}(${subState})` : state;
    return result;
  };
  return (
    <div style={{ display: 'flex' }}>
      <Tag style={{ fontWeight: 400 }}>{locale.resource[vdGroup.groupType]}</Tag>
      <Tag style={{ fontWeight: 400 }} color={getStateIconInfo(vdGroup.state)}>
        {stateStr() || locale.resource['txt.unknown']}
      </Tag>
    </div>
  );
}

const { useBreakpoint } = Grid;

/**
 * ! deprecated #188586 배포 후 이상없으면 삭제 예정
 * 접속 가능한 VD 목록
 * @param {object} history 다운로드 페이지 이동을 위함
 * @returns ReactNode
 */
function AvailableGroupVds({ refresh, setVdsRefresh, onRefresh, redirectUrlToVd }) {
  // contexts
  const theme = useContext(ThemeContext);
  const config = useContext(ConfigContext);
  const { locale } = useContext(LocaleContext);
  const isHanabank = !!(config.siteName && config.siteName.startsWith('hanabank')); // TODO #188586 머지될 때 사이트 커스텀으로 이동해야 함

  // states
  const [vdGroups, setVdGroups] = useState([]);
  const [selectedVdGroup, setSelectedVdGroup] = useState(undefined);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [useMultiMonitor, setUseMultiMonitor] = useState(false);
  const [connHistory, setConnHistory] = useState([]);
  const [visible, setVisible] = useState(false);
  const [reloading, setReloading] = useState(true);
  const [customUrls, setCustomUrls] = useState();
  const [defaultDesktopViewer, setDefaultDesktopViewer] = useState('');
  const [currentSlide, setCurrentSlide] = useState(0); // carousel인 경우 현재 슬라이드 번호
  const breakpoint = useBreakpoint();
  const screens = mediaScreen(breakpoint);

  // refs
  const carouselRef = useRef(null);

  useEffect(() => {
    if (config.defaultDesktopViewer === 'carousel') {
      const idx = vdGroups.findIndex((el) =>
        _.isMatch(el, _.pick(selectedVdGroup, ['id', 'vdId'])),
      );
      if (idx !== -1) setCurrentSlide(idx);
    }

    if (selectedVdGroup?.id) {
      // VD/VD 그룹의 업데이트가 있으면 실시간으로 반영한다.
      listenMessage({ scope: 'common', name: 'broadcast/vd-updated' }, (result) => {
        const updateData = result.data.find((d) => d.id === selectedVdGroup.vdId);

        // 사용자 할당 해제된 경우에는 VD 정보가 사라진 것이기 때문에 VD 그룹 정보를 가져와야하는데
        // 그 시점에 selectedVdGroup에는 VD상태가 박혀있다 따라서 그냥 리로드 시킨다...
        if (
          (updateData && Object.keys(updateData).includes('user') && !updateData.user) ||
          updateData?.state === 'vd-group.state.disposed'
        ) {
          setSelectedVdGroup(null);
          setReloading(true);

          return;
        }

        // 삭제된 VD 그룹이 존재하면 리로드한다.
        const disposedVdgroupData = result.data.filter(
          (d) =>
            vdGroups.find((vdgroup) => vdgroup.id === d.id) &&
            d.state === 'vd-group.state.disposed',
        );

        if (disposedVdgroupData.length) {
          setSelectedVdGroup(null);
          setReloading(true);

          return;
        }

        // 브로드 캐스트 메시지를 받으면 현재 바라보고있는 VD 또는 VD 그룹에 대한 업데이트 메시지
        // 를 찾아서 반영한다.
        setSelectedVdGroup((prev) => {
          const pid = prev.vdId || prev.id;
          const updateData1 = result.data.find((d) => d.id === pid);

          if (
            !updateData1 ||
            (updateData1.user && updateData1.user !== localStorage.getItem('userId'))
          ) {
            return prev;
          }

          delete updateData1.id;

          return {
            ...prev,
            ...updateData1,
          };
        });

        if (config.defaultDesktopViewer === 'carousel') {
          setVdsRefresh(true);
        }
      });
    }

    const view = vdGroups.map((group) => ({ type: 'group', id: group.id }));
    sendMessage({
      scope: 'common',
      name: 'set-view',
      data: {
        payload: {
          view: selectedVdGroup?.vdId ? [...view, { type: 'vd', id: selectedVdGroup?.vdId }] : view,
        },
      },
    });
  }, [selectedVdGroup]);

  /**
   * VD 접속하기
   *  - vsclient 설치 여부 확인한 후
   *  - Protocol Handler를 사용하여 vsclient 앱을 실행한다.
   *  - 이때 vsclient에 로그인 정보를 전달한다.(vsclient가 vsmgmt에게 connection 정보를 받아오기 위해 auth token이 필요하다. Cookie에서 parsing 해온다.)
   * TODO
   *  - 에이전트 미설치시 경고 필요: window.blur 이벤트로는 감지 못해 롤백함, qa-management#182
   */
  const connect = async (vd, isUnassigned) => {
    // ! FIXME: websocket기반이 되면서 getXauthFromCookie는 필요없어짐 하지만 없애면 기존 사용자들이
    // !        자동 로그인이 풀리기 때문에 토큰을 가져와야함
    // !        추후 해당 형상이 어느정도 안정화 운영이 된다면 cookie에 token이 존재하지 않으니 없애도된다.
    const token = localStorage.getItem('token') || getXauthFromCookie();

    const vdGroup = vd || selectedVdGroup;

    if (token) {
      // 토큰은 존재하나 패스워드가 변경되어 인증에 실패한 경우를 처리
      try {
        await checkAccount(token);

        // ! FIXME: websocket기반이 되면서 getXauthFromCookie는 필요없어짐 하지만 없애면 기존 사용자들이
        // !        자동 로그인이 풀리기 때문에 토큰을 가져와야함
        // !        추후 해당 형상이 어느정도 안정화 운영이 된다면 cookie에 token이 존재하지 않으니 없애도된다.
        if (!localStorage.getItem('token')) {
          localStorage.setItem('token', token);
        }
      } catch (e) {
        Modal.warning({
          title: locale.resource['txt.auth-fail'],
          content: locale.resource['txt.session-expired-or-pwd-change'],
          onOk: async () => {
            await accountLogout();
          },
        });
        return;
      }

      const searchParam = {
        'conn-req-url': `${window.location.protocol}//${window.location.host}/portal/api/session/vds/${vdGroup.vdId}/connection`,
        'conn-token': token,
        userId: localStorage.getItem('userId'),
        userName: localStorage.getItem('username'),
        adAccount: vdGroup.adAccount || null,
        vdIp: vdGroup.ipAddress || null,
        vdId: vdGroup.vdId,
        vdGroupType: vdGroup.groupType,
        vdGroupId: vdGroup.groupId || vdGroup.id,
        vdGroupName: vdGroup.groupName || vdGroup.vdGroupName,
        ...(useMultiMonitor && { 'multi-monitors': true }),
      };

      if (redirectUrlToVd && localStorage.getItem('redirectComplete') !== 'true') {
        searchParam['redirect-url'] = redirectUrlToVd;
      }

      // vsclient에게 넘겨줄 args
      const args = new URLSearchParams(searchParam).toString();

      // #191683 windows인 clagent로 접속하고 그 외 Protocol Handler로 접속한다. 또한 윈도우가 아니면 url 리다이렉션 기능을 제공하지 않는다.
      if (isWindows) {
        try {
          await vsClagentPortCheck();

          if (redirectUrlToVd && localStorage.getItem('redirectComplete') !== 'true') {
            await VsclientService.redirectUrl(args);
            localStorage.setItem('redirectComplete', true);
            return;
          }

          await VsclientService.start(args);
        } catch (e) {
          const agt = await startAgent(6);

          if (
            theme?.protocolHandler &&
            !redirectUrlToVd &&
            localStorage.getItem('redirectComplete') !== 'true'
          ) {
            window.location = `VdiVsclient:${btoa(args)}`;
            return;
          }

          // ie 버전에서 axios request가 액세스가 거부되었습니다 에러가 난 경우 window open으로 처리
          const url =
            redirectUrlToVd && localStorage.getItem('redirectComplete') !== 'true'
              ? `http://localhost:${localStorage.getItem(
                  'clagentPort',
                )}/vsclient/redirect-url?args=${btoa(args)}`
              : `http://localhost:${localStorage.getItem('clagentPort')}/vsclient/start?args=${btoa(
                  args,
                )}`;

          if (agt) {
            const ret = window.open(url);
            // eslint-disable-next-line no-unused-expressions
            isUnassigned && unassignVds({ vdIds: [vdGroup?.vdId] });
            setReloading(true);

            if (!ret) {
              Modal.warning({
                title: locale.resource['task-result.state.check'],
                content: locale.resource['txt.retry-after-vsclagent-check'],
              });
              return;
            }

            setTimeout(() => {
              ret.close();
            }, 3 * 1000);
          } else {
            Modal.warning({
              title: locale.resource['task-result.state.check'],
              content: locale.resource['txt.retry-after-vsclagent-check'],
            });
          }
        }
      } else {
        window.location = `VdiVsclient:${btoa(args)}`;
      }
    } else {
      Modal.error({
        title: locale.resource['txt.connect-fail'],
        content: locale.resource['txt.no-login-session'],
      });
    }
  };

  const vdAction = async (
    action,
    targetVd = {
      id: selectedVdGroup.id,
      vdId: selectedVdGroup.vdId,
    },
  ) => {
    const data = await requestVdAction(targetVd.id, targetVd.vdId, action);
    const createMarkup = (value) => {
      return { __html: value };
    };
    if (data.status === 'error') {
      Modal.error({
        title: locale.resource['txt.operation-failed'],
        content: locale.resource[data.message],
      });
    } else {
      Modal.success({
        title: locale.resource['txt.operation-success'],
        content: (
          <Typography.Text style={{ fontSize: 16 }}>
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={createMarkup(locale.resource[data.message])}
            />
          </Typography.Text>
        ),
        onOk: () => {
          setReloading(true);
        },
      });
    }
  };

  /**
   * 장애 조치가 불가능한 상태인지 체크한다.
   * https://gitlab.somansa.com/vdis/vsportal/-/wikis/Knowledge-Base#portal%EC%97%90%EC%84%9C-%EC%9E%A5%EC%95%A0-%EC%A1%B0%EC%B9%98%EA%B0%80-%EB%B6%88%EA%B0%80%ED%95%9C-%EC%83%81%ED%99%A9
   * @param {string} action 장애 조치 키를 의미하며 theme.failover에 정의되어 있음 + 접속 키
   * @param {object} vd VD 정보
   * @returns true(불가) | false(가능)
   */
  const disabledFailover = (action, vd) => {
    const { user, state, subState } = vd;
    let result;
    switch (action) {
      case 'connect':
        result =
          !(
            state?.endsWith('.state.ready') ||
            state?.endsWith('.state.idle') ||
            state?.endsWith('.state.connected') ||
            state?.endsWith('.state.disconnected') ||
            state?.endsWith('.state.stopped')
          ) ||
          subState?.endsWith('.sub-state.session-timeout') ||
          subState?.endsWith('.sub-state.agent-logoff');
        break;
      case 'start':
        if (!user) {
          result = true;
        } else {
          result =
            !state.endsWith('.state.stopped') ||
            subState.endsWith('.sub-state.migrating') ||
            subState.endsWith('.sub-state.session-timeout');
        }
        break;
      case 'soft-reboot':
        if (!user) {
          result = true;
        } else {
          result =
            !(
              state.endsWith('.state.ready') ||
              state.endsWith('.state.idle') ||
              state.endsWith('.state.disconnected')
            ) ||
            subState.endsWith('.sub-state.migrating') ||
            subState.endsWith('.sub-state.session-timeout') ||
            subState?.endsWith('.sub-state.agent-logoff');
        }
        break;
      case 'hard-reboot':
        if (!user) {
          result = true;
        } else if (isHanabank) {
          // TODO #188586 머지될 때 사이트 커스텀으로 이동해야 함
          result =
            subState.endsWith('.sub-state.migrating') ||
            subState.endsWith('.sub-state.session-timeout') ||
            subState?.endsWith('agent-logoff');
        } else {
          result =
            !(
              state.endsWith('.state.ready') ||
              state.endsWith('.state.idle') ||
              state.endsWith('.state.disconnected') ||
              state.endsWith('.state.stopped') ||
              state.endsWith('.state.error')
            ) ||
            subState.endsWith('.sub-state.migrating') ||
            subState.endsWith('.sub-state.session-timeout') ||
            subState?.endsWith('.sub-state.agent-logoff');
        }
        break;
      case 'remoteHelpService':
        result = false;
        break;
      default:
        result = false;
        break;
    }
    return result;
  };

  const getTooltipMsg = (vd) => {
    const getstate = vd.state?.split('.').pop();
    return locale.resource[`txt.connect.vd-${getstate}`];
  };
  const autoConnect = async (vdData, endTime) => {
    if (!vdData) {
      return;
    }
    if (!vdData.isAccessibleIp) {
      notification.warning({
        message: locale.resource['txt.fail-auto-connection'],
        description: locale.resource['txt.inaccessible-ip-msg'],
      });
      return;
    }

    const isAutoConnectSet = await getCustomClient();

    if (localStorage.getItem('autoConnect') === 'true' && isAutoConnectSet) {
      // 접속 시도 중
      localStorage.setItem('autoConnect', false);
      if (!vdData.adAccount) {
        // VD에 할당되어있지 않은 경우
        // 1. 할당해라
        // 2. 접속해라
        const vd = await assignVd({ vdGroupId: vdData.id, vdId: vdData.vdId });
        if (vd.state.endsWith('stopped')) {
          connectByVdStart(vd, moment().add(3, 'm'), (groupInfo) => connect(groupInfo));
        } else {
          connect(vd);
        }

        return;
      }

      if (vdData.state === 'vd.state.stopped') {
        connectByVdStart(vdData, endTime, (groupInfo) => connect(groupInfo));
        // 10초에 한 번씩 get 날려서 VD 상태가 접속 가능한 상태인지 파악
        // 접속 가능해지면 바로 연결
        // 3분이 지나도 연결 가능한 상태가 아니라면 접속 실패 모달 띄움
      } else if (!disabledFailover('connect', vdData)) {
        connect(vdData);
      }
    }
    // const tes = ;
  };

  useEffect(() => {
    const fetchAvailableVdGroups = async () => {
      setIsError(false);
      setIsLoading(true);

      if (!Object.keys(locale?.resource)?.length) {
        return;
      }

      const res = await getAvailableVdGroups();

      const {
        application: {
          vsclient: { autoConnect: configAutoConnect },
        },
      } = await getCurrentConfig();

      if (res.status !== 'error' && res.data.length === 0) {
        notification.warning({
          message: locale.resource['txt.fail-auto-connection'],
          description: locale.resource['txt.no-available-desktops'],
        });
        setIsLoading(false);
        return;
      }

      if (res.status === 'error') {
        notification.warning({
          message: locale.resource['txt.fail-auto-connection'],
          description: locale.resource[res.errorMsg],
        });
        setIsLoading(false);
        return;
      }

      const availableVds = res.data.sort((a, b) => {
        if (a.vdGroupName < b.vdGroupName) {
          return -1;
        }
        if (a.vdGroupName === b.vdGroupName) {
          if (a.adAccount < b.adAccount) {
            return -1;
          }
          return 1;
        }
        return 1;
      });

      let index = -1;
      availableVds.forEach((d, idx) => {
        if (d.autoLogin === true) {
          index = idx;
        }
      });

      try {
        setVdGroups(availableVds);
        if (!selectedVdGroup) {
          setSelectedVdGroup(availableVds[index === -1 ? 0 : index]);
          // 자동 로그인 시도 만약 꺼져있으면 3분 동안 켜지길 기다림
          if (index !== -1) {
            // eslint-disable-next-line no-unused-expressions
            await autoConnect(availableVds[index], moment().add(3, 'm'));
          } else if (configAutoConnect) {
            notification.warning({
              message: locale.resource['txt.fail-auto-connection'],
              description: locale.resource['txt.check-auto-connection'],
            });
          }
        } else {
          const idx = availableVds.findIndex((el) =>
            _.isMatch(el, _.pick(selectedVdGroup, ['id', 'vdId'])),
          );
          if (config.defaultDesktopViewer === 'carousel') {
            if (idx !== -1) {
              setCurrentSlide(idx);
              setSelectedVdGroup(availableVds[idx]);
            }
          } else {
            setSelectedVdGroup((prevState) =>
              availableVds.find(
                (vdGroup) => prevState.vdId === vdGroup.vdId && prevState.id === vdGroup.id,
              ),
            );
          }
        }
      } catch (err) {
        // ACL에 걸린 경우 다른 VD를 사용할 수 있도록 경고만 해준다.
        if (err !== 'assign-vd.fail.no-ip-accessible-vds') {
          setIsError(true);
          setErrorMsg(locale.resource[err] || err);
        }
      }
      setIsLoading(false);
    };
    if (reloading && Object.keys(locale?.resource)?.length) {
      fetchAvailableVdGroups().catch((e) => console.error(e));
      setReloading(false);
    }

    if (refresh) {
      fetchAvailableVdGroups();
      onRefresh();
    }
  }, [reloading, refresh, locale?.resource]);

  // 최근 접속일을 가져온다. 최근 접속일은 selectedVdGroup에 의존한다.
  // 선택된 그룹이 달라지면 view가 바뀌었다고 wss에 알린다.
  useEffect(async () => {
    if (selectedVdGroup?.id) {
      setConnHistory(
        (await getConnHistory({ vdGroupId: selectedVdGroup?.id, vdId: selectedVdGroup?.vdId })).map(
          (item) => ({
            ...item,
            loginDate: moment(item.loginDate).format('YYYY-MM-DD HH:mm:ss'),
            logoutDate: item.logoutDate
              ? moment(item.logoutDate).format('YYYY-MM-DD HH:mm:ss')
              : '-',
          }),
        ),
      );
    }
  }, [selectedVdGroup?.id]);

  useEffect(() => {
    getCustomRedirect().then((customUrl) => setCustomUrls(customUrl));
    setDefaultDesktopViewer(config.defaultDesktopViewer);
  }, []);

  // 자가 조치 컴포넌트
  function Failovers() {
    const result = Object.entries(theme.failover).map(([key, value]) => {
      let icon;
      let title;
      switch (key) {
        case 'start':
          icon = <AiOutlinePoweroff />;
          break;
        case 'soft-reboot':
          icon = <AiOutlineRedo />;
          title = locale.resource['txt.inaccessible-vd-msg'];
          break;
        case 'hard-reboot':
          icon = <AiOutlineSync />;
          title = locale.resource['txt.reboot-for-recovery'];
          break;
        case 'remoteHelpService':
          icon = <AiOutlineWarning />;
          break;
        default:
          icon = <AiOutlineQuestion />;
          break;
      }

      return (
        <Tooltip key={key} title={title} overlayInnerStyle={{ wordBreak: 'keep-all' }}>
          <StyledBtn
            icon={<Icon name={icon} />}
            disabled={disabledFailover(key, selectedVdGroup) || !selectedVdGroup.isAccessibleIp}
            onClick={() =>
              key === 'remoteHelpService'
                ? window.open(customUrls?.remoteHelpService, '', '')
                : Modal.confirm({
                    title: locale.resource[value.text],
                    content: locale.resource['txt.take-long-time'],
                    onOk() {
                      vdAction(key);
                    },
                  })
            }
          >
            {locale.resource[value.text]}
          </StyledBtn>
        </Tooltip>
      );
    });

    return (
      <Space direction="vertical" size={4}>
        {result}
      </Space>
    );
  }

  // 접속 버튼 위에 표시할 메시지(접속 불가한 사유가 표시될 듯)
  const desktopMessage = (vd) => {
    const msg = [];
    if (!vd.isAccessibleIp) {
      msg.push(`${locale.resource['txt.inaccessible-ip-msg']}\n`);
    }
    if (vd.subState === 'vd.sub-state.session-timeout') {
      msg.push(`${locale.resource['txt.session-timeout-msg']}\n`);
    }
    return msg;
  };

  const gotoNext = () => {
    setVdsRefresh(true);
    carouselRef.current.next();
  };

  const gotoPrev = () => {
    setVdsRefresh(true);
    carouselRef.current.prev();
  };

  useEffect(() => {
    setSelectedVdGroup(vdGroups[currentSlide]);
  }, [currentSlide]);

  if (isError) {
    return (
      <StyledResult
        status="warning"
        title={
          errorMsg ? (
            `${errorMsg}`
          ) : (
            <div style={{ fontSize: 16, whiteSpace: 'pre-line' }}>
              {locale.resource['txt.error-occurred']}
            </div>
          )
        }
      />
    );
  }
  if (vdGroups.length === 0) {
    return (
      <StyledResult
        status="info"
        title={<div style={{ fontSize: 16 }}>{locale.resource['txt.no-available-desktops']}</div>}
      />
    );
  }

  // 접속 버튼 클릭시
  const onConnect = async (targetVd = selectedVdGroup) => {
    if (disabledFailover('connect', targetVd)) {
      Modal.error({
        title: locale.resource['txt.connect-fail'],
        content: locale.resource['txt.inaccessible-vd-state'],
      });
    } else if (!targetVd.adAccount) {
      // VD에 할당되어있지 않은 경우
      // 1. 할당해라
      // 2. 접속해라
      const vd = await assignVd({
        vdGroupId: targetVd.id,
        vdId: targetVd.vdId,
      });
      const isUnassigned = true;
      // eslint-disable-next-line no-unused-expressions
      vd.state.endsWith('stopped')
        ? connectByVdStart(vd, moment().add(3, 'm'), (groupInfo) => connect(groupInfo))
        : connect(vd, isUnassigned);
    } else {
      // eslint-disable-next-line no-unused-expressions
      targetVd.state.endsWith('.state.stopped')
        ? connectByVdStart(targetVd, moment().add(3, 'm'), (groupInfo) => connect(groupInfo))
        : connect(targetVd);
    }
  };

  const desktopViewer = {
    carousel: (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: 24,
          minHeight: 240,
        }}
      >
        <Button
          shape="circle"
          icon={<AiOutlineLeft className="anticon" />}
          onClick={gotoPrev}
          style={{ visibility: vdGroups.length > 1 ? 'visible' : 'hidden' }}
        />
        <Spin spinning={isLoading}>
          <div className="carouselCard">
            <StyledCarousel
              dots={false}
              ref={carouselRef}
              initialSlide={currentSlide}
              afterChange={(current) => {
                setCurrentSlide(current);
              }}
            >
              {vdGroups.map((el) => (
                <VdInfo key={el.id + el.vdId} screens={screens}>
                  <Row gutter={[64, 8]} align="middle" justify="center">
                    <Col flex="340px">
                      <Typography.Title level={3} style={{ fontWeight: 500 }}>
                        <Space align="center">
                          <AiFillWindows className="anticon" fontSize={32} color="#0078D6" />
                          <Typography.Text ellipsis style={{ display: 'inline-block', width: 200 }}>
                            {el.adAccount || el.vdGroupName}
                          </Typography.Text>
                          <Tooltip title={locale.resource['txt.refresh']}>
                            <Button
                              type="text"
                              icon={<AiOutlineReload className="anticon" />}
                              onClick={() => setVdsRefresh(true)}
                            />
                          </Tooltip>
                        </Space>
                      </Typography.Title>
                      <Divider style={{ margin: '16px 0' }} />
                      <div className="carouselCard__flavor">
                        <div>
                          <div className="carouselCard__label">{locale.resource['txt.cpu']}</div>
                          <Typography.Title level={4} style={{ fontWeight: 500 }}>
                            {el.cpus}
                            {locale.resource['txt.core']}
                          </Typography.Title>
                        </div>
                        <div>
                          <div className="carouselCard__label">{locale.resource['txt.memory']}</div>
                          <Typography.Title level={4} style={{ fontWeight: 500 }}>
                            {displaySize(el.ram).size}
                            {displaySize(el.ram).unit}
                          </Typography.Title>
                        </div>
                        <div>
                          <div className="carouselCard__label">{locale.resource['txt.disk']}</div>
                          <Typography.Title level={4} style={{ fontWeight: 500 }}>
                            {el.size}
                            {locale.resource['txt.gb']}
                          </Typography.Title>
                        </div>
                      </div>
                      <div>
                        <Space>
                          <div className="carouselCard__label">{locale.resource['txt.os']}: </div>
                          {el.os}
                        </Space>
                      </div>
                      <div>
                        <Space>
                          <div className="carouselCard__label">{locale.resource['txt.ip']}: </div>
                          {el.ipAddress || locale.resource['txt.not-assigned-yet']}
                        </Space>
                      </div>
                      <div>
                        <Space>
                          <div className="carouselCard__label">
                            {locale.resource['txt.status']}:{' '}
                          </div>
                          <RenderTags vdGroup={el} locale={locale} />
                        </Space>
                      </div>
                    </Col>
                    <Col>
                      <div className="monitor">
                        <Space
                          direction="vertical"
                          style={{
                            display: 'flex',
                            width: '100%',
                            height: 120,
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          {desktopMessage.length && (
                            <Typography.Paragraph
                              style={{
                                marginBottom: 0,
                                textAlign: 'center',
                                color: '#ffffff',
                                whiteSpace: 'pre-line',
                              }}
                            >
                              {desktopMessage(el)}
                            </Typography.Paragraph>
                          )}

                          <Tooltip
                            title={
                              (packageJson.config?.preid === 'hanabank' ||
                                packageJson.config?.preid === 'hanabank-dev') &&
                              getTooltipMsg(el)
                            }
                          >
                            <ConnectBtn
                              size="large"
                              disabled={disabledFailover('connect', el) || !el.isAccessibleIp}
                              icon={<AiFillApi className="anticon" fontSize={24} />}
                              onClick={async () => {
                                if (disabledFailover('connect', el)) {
                                  Modal.error({
                                    title: locale.resource['txt.connect-fail'],
                                    content: locale.resource['txt.inaccessible-vd-state'],
                                  });
                                } else if (!el.adAccount) {
                                  // VD에 할당되어있지 않은 경우
                                  // 1. 할당해라
                                  // 2. 접속해라
                                  const vd = await assignVd({
                                    vdGroupId: el.id,
                                    vdId: el.vdId,
                                  });
                                  const isUnassigned = true;
                                  // eslint-disable-next-line no-unused-expressions
                                  vd.state.endsWith('stopped')
                                    ? connectByVdStart(vd, moment().add(3, 'm'), (groupInfo) =>
                                        connect(groupInfo),
                                      )
                                    : connect(vd, isUnassigned);
                                } else {
                                  // eslint-disable-next-line no-unused-expressions
                                  el.state.endsWith('.state.stopped')
                                    ? connectByVdStart(el, moment().add(3, 'm'), (groupInfo) =>
                                        connect(groupInfo),
                                      )
                                    : connect(el);
                                }
                              }}
                            >
                              {locale.resource['txt.connect']}
                            </ConnectBtn>
                          </Tooltip>
                          <Checkbox
                            style={{ color: '#ffffff' }}
                            onChange={(e) => setUseMultiMonitor(e.target.checked)}
                            disabled={!el.isAccessibleIp}
                            checked={useMultiMonitor}
                          >
                            {locale.resource['txt.dual-monitor']}
                          </Checkbox>
                        </Space>
                        <div style={{ marginTop: 40, textAlign: 'right' }}>
                          <Dropdown
                            overlay={() => {
                              const menuItems = Object.entries(theme.failover).map(
                                ([key, value]) => {
                                  return (
                                    <Menu.Item
                                      key={key}
                                      disabled={disabledFailover(key, el) || !el.isAccessibleIp}
                                      onClick={() =>
                                        key === 'remoteHelpService'
                                          ? window.open(customUrls?.remoteHelpService, '', '')
                                          : Modal.confirm({
                                              title: locale.resource[value.text],
                                              content: locale.resource['txt.take-long-time'],
                                              onOk() {
                                                vdAction(key);
                                              },
                                            })
                                      }
                                    >
                                      {locale.resource[value.text]}
                                    </Menu.Item>
                                  );
                                },
                              );
                              return <Menu>{menuItems}</Menu>;
                            }}
                          >
                            <Button type="text" icon={<Icon name={<TiSpanner />} />}>
                              {locale.resource['txt.self-failover']}
                            </Button>
                          </Dropdown>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </VdInfo>
              ))}
            </StyledCarousel>
          </div>
        </Spin>
        <Button
          shape="circle"
          icon={<AiOutlineRight className="anticon" />}
          onClick={gotoNext}
          style={{ visibility: vdGroups.length > 1 ? 'visible' : 'hidden' }}
        />
      </div>
    ),
    list: (
      <Spin spinning={isLoading}>
        <Row gutter={[16, 0]} style={{ flexWrap: 'nowrap' }}>
          <Col flex="none">
            <Space style={{ marginBottom: 8 }}>
              <span style={{ fontSize: 14, fontWeight: 500 }}>
                {locale.resource['txt.available-desktops']}
              </span>
              <Tooltip title={locale.resource['txt.refresh']}>
                <Button
                  type="text"
                  icon={<AiOutlineReload className="anticon" />}
                  onClick={() => setVdsRefresh(true)}
                />
              </Tooltip>
            </Space>
            <VdMenu
              defaultSelectedKeys={`${vdGroups[0].id}+${vdGroups[0].vdId}`}
              selectedKeys={`${selectedVdGroup?.id}+${selectedVdGroup?.vdId}`}
            >
              {vdGroups.map((vdGroup) => (
                <Menu.Item
                  key={`${vdGroup.id}+${vdGroup.vdId || selectedVdGroup?.vdId}`}
                  onClick={async () => {
                    setConnHistory(
                      (await getConnHistory({ vdGroupId: vdGroup.id, vdId: vdGroup.vdId })).map(
                        (item) => ({
                          ...item,
                          loginDate: moment(item.loginDate).format('YYYY-MM-DD HH:mm:ss'),
                          logoutDate: item.logoutDate
                            ? moment(item.logoutDate).format('YYYY-MM-DD HH:mm:ss')
                            : '-',
                        }),
                      ),
                    );
                    getAvailableVdDetail({ vdGroupId: vdGroup.id, vdId: vdGroup.vdId }).then(
                      (res) => {
                        setSelectedVdGroup(res);
                      },
                    );
                  }}
                >
                  {vdGroup.vdId
                    ? `${vdGroup.vdGroupName}(${vdGroup.adAccount})`
                    : vdGroup.vdGroupName}
                </Menu.Item>
              ))}
            </VdMenu>
          </Col>
          <Col flex="auto">
            <VdInfo screens={screens}>
              {selectedVdGroup ? (
                <Row gutter={[20, 8]} align="middle">
                  {screens.isDesktop && (
                    <Col flex="240px">
                      <Typography.Title level={4} ellipsis>
                        <Space direction="vertical" size={4}>
                          <RenderTags vdGroup={selectedVdGroup} locale={locale} />
                          <Space align="center">
                            <AiFillWindows className="anticon" fontSize={24} />
                            <Typography.Text ellipsis style={{ width: 200 }}>
                              {selectedVdGroup.adAccount || selectedVdGroup.vdGroupName}
                            </Typography.Text>
                          </Space>
                        </Space>
                      </Typography.Title>
                      <Descriptions
                        column={1}
                        style={{ marginTop: 16 }}
                        labelStyle={{ padding: '1px 0' }}
                        contentStyle={{ padding: '1px 0' }}
                      >
                        <Descriptions.Item label={locale.resource['txt.os']}>
                          {selectedVdGroup.os}
                        </Descriptions.Item>
                        <Descriptions.Item label={locale.resource['txt.cpu']}>
                          {`${selectedVdGroup.cpus} ${locale.resource['txt.core']}`}
                        </Descriptions.Item>
                        <Descriptions.Item label={locale.resource['txt.memory']}>
                          {`${displaySize(selectedVdGroup.ram).size} ${
                            displaySize(selectedVdGroup.ram).unit
                          }`}
                        </Descriptions.Item>
                        <Descriptions.Item label={locale.resource['txt.disk']}>
                          {`${selectedVdGroup.size} ${locale.resource['txt.gb']}`}
                        </Descriptions.Item>
                        <Descriptions.Item label={locale.resource['txt.ip']}>
                          {selectedVdGroup.ipAddress || locale.resource['txt.not-assigned-yet']}
                        </Descriptions.Item>
                        <Descriptions.Item
                          label={locale.resource['txt.last-date']}
                          style={{ letterSpacing: locale.language.locale === 'en' ? -0.6 : 0 }}
                        >
                          <Space align="start">
                            <span>
                              {connHistory.length
                                ? connHistory[0].loginDate
                                : locale.resource['txt.no-connection-history']}
                            </span>
                            <Button
                              size="small"
                              style={{ position: 'relative', top: -3 }}
                              icon={
                                <span>
                                  <Tooltip title={locale.resource['txt.last-connection-history']}>
                                    <AiOutlineBars className="anticon" />
                                  </Tooltip>
                                </span>
                              }
                              onClick={async () => {
                                setVisible(true);
                              }}
                            />
                            <Modal
                              title={locale.resource['txt.last-connection-history']}
                              visible={visible}
                              width={840}
                              onOk={() => {
                                setVisible(false);
                              }}
                              onCancel={() => {
                                setVisible(false);
                              }}
                              cancelButtonProps={{ style: { display: 'none' } }}
                              destroyOnClose
                            >
                              <Table
                                size="small"
                                rowKey="sessionId"
                                dataSource={connHistory}
                                columns={[
                                  {
                                    title: locale.resource['txt.connected-date'],
                                    dataIndex: 'loginDate',
                                    width: 140,
                                  },
                                  {
                                    title: locale.resource['txt.disconnected-date'],
                                    dataIndex: 'logoutDate',
                                    width: 140,
                                  },
                                  {
                                    title: locale.resource['txt.adAccount'],
                                    dataIndex: 'adAccount',
                                  },
                                  {
                                    title: locale.resource['txt.vdId'],
                                    dataIndex: 'vdId',
                                    width: 268,
                                  },
                                  { title: locale.resource['txt.source-ip'], dataIndex: 'ip' },
                                ]}
                              />
                            </Modal>
                          </Space>
                        </Descriptions.Item>
                      </Descriptions>
                    </Col>
                  )}
                  <Col flex="280px">
                    <div className="monitor">
                      <Space
                        direction="vertical"
                        style={{
                          display: 'flex',
                          width: '100%',
                          height: 120,
                          justifyContent: 'space-between',
                          alignItems: 'center',
                        }}
                      >
                        {desktopMessage.length && (
                          <Typography.Paragraph
                            style={{
                              marginBottom: 0,
                              textAlign: 'center',
                              color: '#ffffff',
                              whiteSpace: 'pre-line',
                            }}
                          >
                            {desktopMessage(selectedVdGroup)}
                          </Typography.Paragraph>
                        )}

                        <Tooltip
                          title={
                            (packageJson.config?.preid === 'hanabank' ||
                              packageJson.config?.preid === 'hanabank-dev') &&
                            getTooltipMsg(selectedVdGroup)
                          }
                        >
                          <ConnectBtn
                            size="large"
                            disabled={
                              disabledFailover('connect', selectedVdGroup) ||
                              !selectedVdGroup.isAccessibleIp
                            }
                            icon={<AiFillApi className="anticon" fontSize={24} />}
                            onClick={onConnect}
                          >
                            {locale.resource['txt.connect']}
                          </ConnectBtn>
                        </Tooltip>
                        <Checkbox
                          style={{ color: '#ffffff' }}
                          onChange={(e) => setUseMultiMonitor(e.target.checked)}
                          disabled={!selectedVdGroup.isAccessibleIp}
                        >
                          {locale.resource['txt.dual-monitor']}
                        </Checkbox>
                      </Space>
                    </div>
                  </Col>
                  <Col style={{ alignSelf: 'flex-start' }}>
                    <Failovers />
                  </Col>
                </Row>
              ) : (
                <div>{locale.resource['txt.select-desktop']}</div>
              )}
            </VdInfo>
          </Col>
        </Row>
      </Spin>
    ),
  };
  // 모바일용 데스크탑
  const SimpleViewer = () => {
    return (
      <>
        <Typography.Title level={5}>{locale.resource['txt.available-desktops']}</Typography.Title>
        <Row gutter={[8, 8]}>
          {vdGroups.map((el) => {
            return (
              <Col span={24} key={el.adAccount + el.vdGroupName}>
                <Card
                  bordered={false}
                  actions={[
                    <Button
                      block
                      type="link"
                      disabled={disabledFailover('connect', el) || !el.isAccessibleIp}
                      icon={<Icon name={<AiFillApi className="anticon" />} />}
                      onClick={() => onConnect(el)}
                    >
                      {locale.resource['txt.connect']}
                    </Button>,
                  ]}
                  style={{
                    boxShadow:
                      '0px 1px 1px rgba(0, 42, 88, 0.08) , 0px 2px 2px rgba(0, 42, 88, 0.08) , 0px 4px 4px rgba(0, 42, 88, 0.08)',
                  }}
                >
                  <Card.Meta
                    title={
                      <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <div>{el.adAccount || el.vdGroupName}</div>
                        <div>
                          <Dropdown
                            overlay={() => {
                              const menuItems = Object.entries(theme.failover).map(
                                ([key, value]) => {
                                  return (
                                    <Menu.Item
                                      key={key}
                                      disabled={disabledFailover(key, el) || !el.isAccessibleIp}
                                      onClick={() =>
                                        key === 'remoteHelpService'
                                          ? window.open(customUrls?.remoteHelpService, '', '')
                                          : Modal.confirm({
                                              title: locale.resource[value.text],
                                              content: locale.resource['txt.take-long-time'],
                                              onOk() {
                                                vdAction(key, el);
                                              },
                                            })
                                      }
                                    >
                                      {locale.resource[value.text]}
                                    </Menu.Item>
                                  );
                                },
                              );
                              return <Menu>{menuItems}</Menu>;
                            }}
                          >
                            <Button type="link" icon={<Icon name={<AiOutlineEllipsis />} />} />
                          </Dropdown>
                        </div>
                      </Space>
                    }
                    description={
                      <Space direction="vertical">
                        <Badge
                          status={getStateIconInfo(el.state)}
                          text={locale.resource[el.subState] || locale.resource['txt.unknown']}
                        />
                        <Space split={<Divider type="vertical" />}>
                          <div>
                            {el.cpus}
                            {locale.resource['txt.core']}/{displaySize(el.ram).size}
                            {displaySize(el.ram).unit}/{el.size}
                            {locale.resource['txt.gb']}
                          </div>
                          <div>{el.os}</div>
                        </Space>
                      </Space>
                    }
                    avatar={<Avatar size={40} icon={<Icon name={<AiOutlineDesktop />} />} />}
                  />
                </Card>
              </Col>
            );
          })}
        </Row>
      </>
    );
  };

  return (
    <div>
      {isWindows && <ClientUpdateCheck />}
      {screens.isMobile ? <SimpleViewer /> : desktopViewer[defaultDesktopViewer]}
    </div>
  );
}

export default AvailableGroupVds;
