import { message, Modal, Space, Spin } from 'antd';
import { currentLocale } from 'locales';
import { forEach, uniq } from 'lodash';
import moment from 'moment';
import { getAvailableVdGroups, requestVdAction } from 'services/websocket/mgmt';
import React from 'react';
import { checkClagent } from 'services/vsclient';
import { getCurrentConfig } from 'services/mgmt';

export function getXauthFromCookie() {
  try {
    return document.cookie
      .split('; ')
      .find((r) => r.startsWith('x_auth'))
      .split('=')[1];
  } catch (_err) {
    return undefined;
  }
}

export function getStateIconInfo(localeKey) {
  let color = 'processing';
  if (
    localeKey.endsWith('state.ready') ||
    localeKey.endsWith('state.idle') ||
    localeKey.endsWith('state.connected')
  ) {
    color = 'success';
  } else if (localeKey.endsWith('state.error')) {
    color = 'error';
  } else if (localeKey.endsWith('state.disconnected') || localeKey.endsWith('state.stopped')) {
    color = 'default';
  }

  return color;
}

/*
 * 추가 삭제 시 mgmt(back-end) validation을 통과하지 못 한 경우 error fields를 골라낸다.
 */
export function getErrorFields(items) {
  // reason이 중복된 것 제거
  // FIXME : GS 인증때문에 급하게 하느라 배열 validation의 경우 index가 나오는 순간 뒤는 무시한다.
  // 검사해야할 schema를 받아서 바꾸도록 수정이 필요해보인다.
  const generateNamePath = (name) => {
    const arrayIndex = name.findIndex((n) => n.match(/\d/));

    // array
    if (arrayIndex !== -1) {
      return name.slice(0, arrayIndex);
    }

    return name;
  };

  const errObjs = {};

  forEach(items, (errItem) => {
    const namePath = generateNamePath(errItem.name);
    const nameString = namePath.join('/');
    if (!errObjs[nameString]) {
      errObjs[nameString] = [];
    }

    errObjs[nameString].push(errItem.reason);
  });

  return Object.entries(errObjs).map(([k, v]) => ({ name: k.split('/'), errors: uniq(v) }));
}

// TODO: websocket 기반으로 변경되었으니 폴링을 제거할 수 있을 것 같다. 하지만 지금은 무리무리 ...
export function connectByVdStart(group, endTime, connectFn) {
  requestVdAction(group.id, group.vdId, 'start');
  Modal.info({
    title: currentLocale['txt.connecting'],
    keyboard: false,
    content: (
      <Space direction="vertical" align="center">
        <Spin spinning />
        <div>
          [{group.vdName || group.adAccount}] {currentLocale['txt.connecting-vd']}
        </div>
      </Space>
    ),
    okButtonProps: { style: { display: 'none' } },
  });

  const intervalId = setInterval(async () => {
    const vdGroup = await getAvailableVdGroups();
    if (vdGroup.status === 'error') {
      message.error(currentLocale[vdGroup.message]);
      clearInterval(intervalId);
      Modal.destroyAll();
      return;
    }
    const groupInfo = vdGroup.data.find((g) => g.vdId === group.vdId);

    if (
      groupInfo.state.endsWith('.state.ready') ||
      groupInfo.state.endsWith('.state.idle') ||
      groupInfo.state.endsWith('.state.connected') ||
      groupInfo.state.endsWith('.state.disconnected')
    ) {
      clearInterval(intervalId);
      Modal.destroyAll();
      connectFn(groupInfo);
    }

    // 현재시간이랑 끝나야하는 시간이랑 비교해서 지났으면
    if (moment().diff(endTime) >= 0) {
      clearInterval(intervalId);
      Modal.destroyAll();

      Modal.error({
        title: currentLocale['txt.fail-auto-connection'],
        content: currentLocale['txt.inaccessible-vd-state'],
      });
    }
  }, 10 * 1000);

  return intervalId;
}

export async function vsClagentPortCheck() {
  try {
    const defaultPort = localStorage.getItem('clagentPort') || '61811';
    // 기본 포트로 시도
    const res = await checkClagent(defaultPort);

    if (res.data.success) {
      localStorage.setItem('clagentPort', defaultPort);
      return true;
    }
  } catch (e) {
    localStorage.removeItem('clagentPort');
    const errroPort = [];
    // 기본 포트가 아닐시 config 조회
    const {
      application: {
        vsclient: { vsclagentPort: configClagentPort },
      },
    } = await getCurrentConfig();

    for (let index = 0; index < configClagentPort.length; index += 1) {
      try {
        // eslint-disable-next-line no-await-in-loop
        const result = await checkClagent(configClagentPort[index]);

        if (result.data.success) {
          localStorage.setItem('clagentPort', configClagentPort[index]);
          break;
        }
      } catch (error) {
        errroPort.push(configClagentPort[index]);
      }
    }

    if (!localStorage.getItem('clagentPort')) {
      console.error(
        `vsclient, vsclagent에 대한 설치 및 서비스 상태를 확인하십시오. vsclagent 접속 시도: 포트(${errroPort.join(
          ', ',
        )}), 타임아웃(0.5s)`,
      );
      return false;
    }
  }
  return true;
}

/**
 * userAgent로 플랫폼이 Windows인지 판별
 * ? https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent
 * ? https://caniuse.com/?search=userAgent
 * ? https://caniuse.com/?search=userAgentData
 * @returns Boolean
 */
export const isWindows = window.navigator.userAgent.includes('Windows');

/**
 * clagent를 실행시키고 실행 여부를 리턴한다.
 * @param {number} endTime 현시각 + N초동안 clagent 실행을 체크한다.
 * @returns boolean true면 실행됨
 */
export function startAgent(endTime) {
  // TODO #191683 이렇게 해도 되나?
  if (!isWindows) {
    return new Promise((resolve) => resolve(true));
  }

  window.location = 'vdivsclagent:';
  const endTimer = moment().add(endTime || 2, 's');
  const modal = Modal.info({
    title: currentLocale['txt.running-client'],
    keyboard: false,
    centered: true,
    content: (
      <Space direction="vertical" align="center">
        <Spin spinning />
        <div>{currentLocale['txt.ready-to-connect']}</div>
      </Space>
    ),
    okButtonProps: { style: { display: 'none' } },
  });

  // 후처리
  const postProcess = (timer) => {
    clearInterval(timer);
    modal.destroy();
  };

  return new Promise((resolve) => {
    const timer = setInterval(() => {
      if (moment().isAfter(endTimer)) {
        postProcess(timer);
        resolve(false);
      }
      vsClagentPortCheck().then((success) => {
        if (success) {
          postProcess(timer);
          resolve(true);
        }
      });
    }, 2 * 1000);
  });
}

/**
 * invalidParams를 국제화하고, '메시지+변수'로 이루어진 reason(object)을 문자열로 변환
 * TODO API v2에서는 antd의 form field 상태 형식으로 리턴받을 것이기 때문에 개발되면 deprecated 예정
 * TODO 치환할 대상이 여러개면 마지막만 치환되는 버그가 있을 것으로 보임 > interpolation 함수 참고
 * @param {Array} validationList invalidParams
 * @returns Array getErrorFields에 적용할 수 있는 array 형태로 반환
 */
export const getValidationMsgs = (validationList) => {
  const newInvalid = validationList.map((item) => {
    const {
      reason,
      reason: { key, values },
    } = item;
    let reasonMsg = '';
    if (typeof reason === 'string') {
      reasonMsg = currentLocale[reason];
    } else {
      // typeof reason === 'object'
      reasonMsg = currentLocale[key];
      Object.entries(values).forEach(([index, value]) => {
        reasonMsg = reasonMsg.replace(`{{${index}}}`, value);
      });
    }
    return { ...item, reason: reasonMsg };
  });
  return newInvalid;
};

/**
 * * API v2에서 validation 메시지 국제화 및 '메시지+변수'를 치환하여 antd form의 error 형식으로 리턴한다.
 * API v2임에도 국제화는 portal에서 하기 때문에 치환 함수가 필요함
 * @param {Array} validationList
 * @param {String} validationList.name  필드명
 * @param {Array} validationList.errorLocaleKey * 국제화 및 치환할 대상
 * @param {String} validationList.errorLocaleKey.key resource key
 * @param {Object} validationList.errorLocaleKey.values 치환할 변수
 * @returns array<object>
 */
export const getValidationMsgs2 = (validationList) => {
  /**
   * reason의 변수를 치환하는 함수(보간법)
   * @param {Array} data array<string | { key, value}>
   * @returns array<string>
   */
  function interpolation(data) {
    return data.map(({ key, values }) => {
      if (!values) {
        return currentLocale[key];
      }
      const rawValues = Object.entries(values);
      const str = rawValues.reduce((acc, cur) => {
        const [index, value] = cur;
        return acc.replace(`{{${index}}}`, value);
      }, currentLocale[key]);
      return str;
    });
  }

  return validationList.map(({ name, errorLocaleKey }) => ({
    name,
    errors: interpolation(errorLocaleKey),
  }));
};

/**
 * locale key를 실제 메시지로 치환한다.
 * @param {String | Object} target 치환할 대상을 의미
 * TODO API v2에서는 antd의 form field 상태 형식으로 리턴받을 것이기 때문에 개발되면 deprecated 예정
 * TODO 치환할 대상이 여러개면 마지막만 치환되는 버그가 있을 것으로 보임 > interpolation 함수 참고
 * target 예시
    1. target: "login.fail.show-attempts"
    2. target: {key: "login.fail.show-attempts", values: {0: 1, 1: 5}}
 * @returns String
 */
export const localeKeyToString = (target) => {
  let result;
  if (typeof target === 'string') {
    result = currentLocale[target];
  } else {
    result = currentLocale[target.key];
    Object.entries(target.values).forEach(([index, value]) => {
      result = result.replace(`{{${index}}}`, value);
    });
  }
  return result;
};

export function convertMBtoGB(mb) {
  const gb = mb / 1024;
  return gb;
}

/**
 * MB 단위를 GB 단위로 변환하여 반환한다.
 * GB 단위가 1 미만이면 MB로 반환
 * @param {number} mb
 * @returns { size: number, unit: string }
 */
export function displaySize(mb) {
  const gb = convertMBtoGB(mb);
  if (gb < 1) {
    return { size: mb, unit: 'MB' };
  }
  return { size: gb, unit: 'GB' };
}

export function convertGBtoMB(gb) {
  const mb = gb * 1024;
  return mb;
}
