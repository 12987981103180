import { Alert, Button, Checkbox, Col, Form, Input, Modal, Row, Space } from 'antd';
import { AppCopyright, AppLogo, Icon } from 'components';
import { LocaleChanger } from 'pages/components';
import { DownloadTable } from 'pages/help/Download';
import React, { useState } from 'react';
import { AiOutlineLock, AiOutlineUser } from 'react-icons/ai';

import moment from 'moment';
import { useLogin } from 'hooks/useLogin';

import meritzImg01 from '../../../images/meritz_img01.png';
import meritzImg02 from '../../../images/meritz_img02.png';
import ResetPassword from '../../../pages/auth/ResetPassword';

const styles = {
  formHeight: {
    height: 40,
  },
};

function Login({ history, location }) {
  // states
  const [downloadModalVisible, setDownloadModalVisible] = useState(false);
  const [serviceGuideLink, setServiceGuideLink] = useState(null);
  const [checked, setChecked] = useState(false);

  const [resetPwdProps, setResetPwdProps] = useState({ visible: false }); // 비밀번호 변경 컴포넌트 props

  const { login, ui, settings } = useLogin({
    setResetPwdProps,
    setServiceGuideLink,
    checked,
    history,
    location,
  });

  const { loginForm, customUrls, credential, handleLoginSuccess, onFinishApi } = login;
  const { doPasswordReset, btnLoading, hideMenus, theme } = ui;
  const { config, locale, error } = settings;

  const { MultiFactor } = config;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        background: '#fcf9f9',
        paddingBottom: 20,
      }}
    >
      <div style={{ position: 'absolute', top: '1em', right: '1em' }}>
        <LocaleChanger />
      </div>
      {moment().isBefore('2024-04-01') && (
        <img src={meritzImg02} alt="망고2 오픈" style={{ marginBottom: 36 }} />
      )}
      <div
        style={{
          margin: '0 auto',
          width: 1000,
          borderRadius: 16,
          backgroundColor: '#ffffff',
          overflow: 'hidden',
          boxShadow:
            '0 1px 1px 1px rgba(0, 0, 0, 0.07), 0 3px 4px -1px rgba(0, 0, 0, 0.07), 0 4px 9px 2px rgba(0, 0, 0, 0.07)',
        }}
      >
        <Row style={{ height: 480, alignItems: 'stretch' }}>
          <Col flex="448px" style={{ padding: '60px 80px 0' }}>
            <Row gutter={[0, 0]}>
              <Col span={24} style={{ paddingBottom: 30 }}>
                <AppLogo />
              </Col>
              <Col span={24}>
                {/* startsWith('require') = require | require-secret-key */}
                {Object.prototype.hasOwnProperty.call(credential, 'twoFactor') &&
                credential.twoFactor.type.startsWith('require') ? (
                  <MultiFactor credential={credential} setCredential={handleLoginSuccess} />
                ) : (
                  <>
                    {error && (
                      <Alert
                        type="error"
                        showIcon
                        style={{ marginBottom: 16 }}
                        message={<div style={{ whiteSpace: 'pre-line' }}>{error}</div>}
                      />
                    )}
                    <Form onFinish={onFinishApi} layout="vertical" form={loginForm}>
                      <Form.Item
                        name="userId"
                        rules={[{ required: true, message: locale.resource['txt.enter-id'] }]}
                      >
                        <Input
                          prefix={<Icon name={<AiOutlineUser focusable={false} />} />}
                          placeholder={locale.resource['txt.login-id']}
                          style={styles.formHeight}
                          autoFocus
                          autoComplete="username"
                        />
                      </Form.Item>
                      <Form.Item
                        name="password"
                        rules={[
                          {
                            required: true,
                            message: locale.resource['txt.enter-password'],
                          },
                        ]}
                      >
                        <Input.Password
                          visibilityToggle={false}
                          allowClear
                          prefix={<Icon name={<AiOutlineLock focusable={false} />} />}
                          placeholder={locale.resource['txt.login-password']}
                          style={styles.formHeight}
                          autoComplete="current-password"
                        />
                      </Form.Item>
                      <div style={{ marginBlock: 16 }}>
                        {config.autoLogin && (
                          <Form.Item name="autoLogin" noStyle>
                            <Checkbox
                              checked={checked}
                              onChange={(e) => setChecked(e.target.checked)}
                            >
                              {locale.resource['txt.auto-login']}
                            </Checkbox>
                          </Form.Item>
                        )}
                        {config.saveId && (
                          <Form.Item
                            name="saveId"
                            noStyle
                            valuePropName="checked"
                            initialValue={false}
                          >
                            <Checkbox>{locale.resource['txt.save-id']}</Checkbox>
                          </Form.Item>
                        )}
                      </div>
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          block
                          style={styles.formHeight}
                          loading={btnLoading}
                        >
                          LOGIN
                        </Button>
                      </Form.Item>
                    </Form>
                  </>
                )}
              </Col>
              <Col span={24} style={{ paddingTop: 10 }}>
                <Row gutter={[0, 8]}>
                  <Col span={24}>
                    <Space>
                      {!hideMenus.includes('signup') && customUrls?.signUp && (
                        <Button
                          type="link"
                          onClick={() => {
                            window.open(
                              customUrls?.signUp,
                              '',
                              `${
                                theme.customUrls?.signUp?.windowFeatures !== undefined
                                  ? theme.customUrls.signUp.windowFeatures
                                  : `height=${window.screen.height},width=${window.screen.width},resizable=yes,fullscreen=yes`
                              }`,
                            );
                          }}
                        >
                          {locale.resource['txt.register-id']}
                        </Button>
                      )}
                      <div>
                        <Button type="link" style={{ padding: 0 }} onClick={doPasswordReset}>
                          {locale.resource['config.features.custom-redirect.password-reset']}
                        </Button>
                        <ResetPassword
                          resetPwdProps={resetPwdProps}
                          setResetPwdProps={setResetPwdProps}
                        />
                      </div>
                    </Space>
                  </Col>
                  <Col span={24}>
                    <Space>
                      {!hideMenus.includes('guide') && (
                        <a href={serviceGuideLink} download className="ant-btn">
                          {locale.resource[theme.serviceGuide.text]}
                        </a>
                      )}
                      {!hideMenus.includes('fileDownload') && (
                        <>
                          <Button onClick={() => setDownloadModalVisible(true)}>
                            {locale.resource['txt.download-client']}
                          </Button>
                          <Modal
                            title={locale.resource['txt.download-client']}
                            centered
                            width={720}
                            visible={downloadModalVisible}
                            onOk={() => setDownloadModalVisible(false)}
                            onCancel={() => setDownloadModalVisible(false)}
                            footer={[
                              <Button key="ok" onClick={() => setDownloadModalVisible(false)}>
                                {locale.resource['txt.ok']}
                              </Button>,
                            ]}
                          >
                            <DownloadTable size="small" locale={locale} />
                          </Modal>
                        </>
                      )}
                    </Space>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col
            flex="auto"
            style={{
              backgroundColor: '#ebf5fc',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <img src={meritzImg01} alt="VD-i 이미지" />
          </Col>
        </Row>
      </div>
      <div style={{ marginTop: 16 }}>
        <AppCopyright />
      </div>
    </div>
  );
}

export default Login;
