import { Alert, Button, Checkbox, Col, Form, Input, Modal, Row } from 'antd';
import { AppCopyright, AppLogo, Icon } from 'components';
import { LocaleChanger } from 'pages/components';
import { DownloadTable } from 'pages/help/Download';
import { localeKeyToString } from 'pages/utils';
import React, { useEffect, useState } from 'react';
import { AiOutlineLock, AiOutlineUser } from 'react-icons/ai';
import { getBioAuthUser } from 'services/websocket/auth';
import { getCurrentConfig } from 'services/mgmt';

// ? why not using websocket api?
// ? 로그인 페이지가 뜰 때 호출되는 api는 rest로 날린다.
// ? 그 이유는 웹소켓 연결이 계속 지속되기 때문이다...
import styled from 'styled-components';
import { useLogin } from 'hooks/useLogin';
import ResetPassword from '../../../pages/auth/ResetPassword';

const styles = {
  formHeight: {
    height: 40,
  },
};

const StyledBackground = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  .loginBox {
    margin: 0 auto;
    padding: 40px;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.07), 0 3px 4px -1px rgba(0, 0, 0, 0.07),
      0 4px 9px 2px rgba(0, 0, 0, 0.07);
  }
`;

const StyledBox = styled.div`
  margin: 0 auto;
  width: 368px;
  padding: 40px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.07), 0 3px 4px -1px rgba(0, 0, 0, 0.07),
    0 4px 9px 2px rgba(0, 0, 0, 0.07);
`;

function Login({ history, location }) {
  // states
  const [downloadModalVisible, setDownloadModalVisible] = useState(false);
  const [checked, setChecked] = useState(false);
  const [isBioAuthUser, setIsBioAuthUser] = useState(undefined); // 생체 인증 대상자 여부
  const [resetPwdProps, setResetPwdProps] = useState({ visible: false }); // 비밀번호 변경 컴포넌트 props
  const [backConf, setBackConf] = useState();

  const { login, ui, settings } = useLogin({
    setResetPwdProps,
    checked,
    history,
    location,
  });

  const { loginForm, credential, handleLoginSuccess, onFinishApi } = login;
  const { doPasswordReset, btnLoading, theme } = ui;
  const { config, locale, error, setError } = settings;

  const { MultiFactor } = config;

  // ! 하나은행 생체인증 커스텀
  // ! PreCheck 페이지에서 생체인증 과정에서 에러가 발생하면 location.message로 에러를 전송한다.
  // * message가 없이 온 경우라면 precheck가 완료된지 여부에 따라 동작
  // * precheck이 돌지 않았는데 로그인페이지로 온 경우는 (사용자가 url에 x.x.x.x/portal/auth/login을 직접 입력한경우) Precheck으로 보낸다.
  useEffect(() => {
    getCurrentConfig()
      .then((data) => {
        setBackConf(data);
      })
      .catch((err) => console.error(err));
    // preCheck 페이지를 거쳐서 온 것이 아니라면 prechecked로 보낸다.
    if (config.preCheck && !location.preChecked && sessionStorage.getItem('logout') !== 'true') {
      history.push({
        pathname: '/auth/pre-check',
        redirectUrlToVd: location?.redirectUrlToVd,
      });

      return;
    }

    if (location.message) {
      setError(localeKeyToString(location.message));
      if (location.message === 'txt.check-required-program') {
        setDownloadModalVisible(true);
      }
    }

    if (backConf?.features?.bioAuth?.use) {
      (async () => {
        const res = await getBioAuthUser(localStorage.getItem('userId'));

        if (res.success) {
          const { registeredQr, registeredOtp, registeredFace, registeredPattern } = res.data?.user;
          const result = [registeredQr, registeredOtp, registeredFace, registeredPattern].some(
            (el) => el === true,
          );
          setIsBioAuthUser(result);
        } else {
          // 생체 인증 서버와 통신을 못하거나, 생체 인증 대상자가 아닌 경우도 res.success가 false로 온다.
          // 따라서 isBioAuthUser === undefined 인 경우 메시지(서버와 통신이 되지 않습니다.) 표시를 제거함
          setIsBioAuthUser(undefined);
        }
      })();
    }

    // #190291
    if (config.autoLogin) {
      setChecked(true);
    }
  }, []);
  useEffect(() => {
    if (backConf?.features?.bioAuth?.use) {
      (async () => {
        const res = await getBioAuthUser(localStorage.getItem('userId'));

        if (res.success) {
          const { registeredQr, registeredOtp, registeredFace, registeredPattern } = res.data?.user;
          setIsBioAuthUser(registeredQr || registeredOtp || registeredFace || registeredPattern);
        } else {
          setIsBioAuthUser(undefined);
        }
      })();
    }
  }, [backConf]);
  /**
   * 커스텀 Url 열기
   * @param {*} url 대상 url
   * @return window.open
   */
  const openCustomUrl = (url) => {
    const windowFeatures =
      theme.customUrls?.passwordReset?.windowFeatures ||
      `height=${window.screen.height},width=${window.screen.width},resizable=yes,fullscreen=yes`;
    window.open(url, '', windowFeatures);
  };

  // 로그인 submit
  const onFinish = async (values) => {
    /**
     * 하나은행용 onFinish 후처리 커스텀
     * @param {*} res 로그인 결과값
     * @param {*} payload 로그인 payload
     */
    const customResult = (res, payload) => {
      if (res.result) {
        if (res.twoFactor.type === 'error' || res.twoFactor.type === 'unknown') {
          setError(locale.resource[res.twoFactor.msg]);
        } else if (
          res.twoFactor.type === 'require' ||
          res.twoFactor.type === 'require-secret-key'
        ) {
          handleLoginSuccess({
            success: false,
            idpw: {
              userId: res.userId,
              password: payload.password1,
              username: res.username,
              autoLogin: checked,
              autoConnect: true,
              redirectComplete: false,
              // 토큰 없음
            },
            twoFactor: res.twoFactor,
          });
        } else {
          handleLoginSuccess({
            success: true,
            idpw: {
              userId: res.userId,
              username: res.username,
              autoLogin: checked,
              autoConnect: true,
              redirectComplete: false,
              token: res.token,
              saveId: payload.saveId ?? false,
            },
          });
        }
      } else if (res.ldapCode === 773 || res.ldapCode === 532) {
        if (backConf?.features?.bioAuth?.use && isBioAuthUser) {
          openCustomUrl('http://asi.infohana.com:8082');
        } else {
          setResetPwdProps({ visible: true, userId: res.userId, msg: res.msg });
        }
      } else {
        setError(localeKeyToString(res.msg));
      }
    };

    await onFinishApi(values, customResult);
  };

  /**
   * 비밀번호 초기화하기
   *   방법 1. InputForResetPWD(자체): 입력받은 아이디의 이메일로 초기화 URL을 보내 처리
   *   방법 2. windowOpen(외부): 환경설정 > 기능 > Portal 내 링크 커스터마이징 > 비밀번호 초기화 url을 연다.
   * @returns ReactNode || window.open
   */
  const hanbankPasswordReset = async () => {
    let externalUrl; // 비밀번호 초기화 url
    // #186818 생체 인증을 사용하는 경우 생체인증 서버에 인증 대상인지 질의한다.
    if (backConf?.features?.bioAuth?.use) {
      if (isBioAuthUser) {
        externalUrl = 'http://asi.infohana.com:8082';
      }
    }
    return doPasswordReset(undefined, externalUrl);
  };

  // TODO 로그인페이지 로직 분리시 제거
  return (
    <StyledBackground>
      <div style={{ position: 'absolute', top: '1em', right: '1em' }}>
        <LocaleChanger />
      </div>
      <StyledBox style={config.loginBoxStyles}>
        <Row gutter={[0, 32]}>
          <Col span={24} style={{ textAlign: 'center' }}>
            <AppLogo />
          </Col>
          <Col span={24}>
            {/* startsWith('require') = require | require-secret-key */}
            {Object.prototype.hasOwnProperty.call(credential, 'twoFactor') &&
            credential.twoFactor.type.startsWith('require') ? (
              <MultiFactor credential={credential} setCredential={handleLoginSuccess} />
            ) : (
              <>
                {error && (
                  <Alert
                    type="error"
                    showIcon
                    style={{ marginBottom: 24 }}
                    message={<div style={{ whiteSpace: 'pre-line' }}>{error}</div>}
                  />
                )}
                <Form onFinish={onFinish} layout="vertical" form={loginForm}>
                  <Form.Item
                    name="userId"
                    rules={[{ required: true, message: locale.resource['txt.enter-id'] }]}
                  >
                    <Input
                      prefix={<Icon name={<AiOutlineUser focusable={false} />} />}
                      placeholder={locale.resource['txt.login-id']}
                      style={styles.formHeight}
                      autoFocus
                      autoComplete="username"
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: locale.resource['txt.enter-password'],
                      },
                    ]}
                  >
                    <Input.Password
                      visibilityToggle={false}
                      allowClear
                      prefix={<Icon name={<AiOutlineLock focusable={false} />} />}
                      placeholder={locale.resource['txt.login-password']}
                      style={styles.formHeight}
                      autoComplete="current-password"
                    />
                  </Form.Item>
                  <div style={{ marginBlock: 16 }}>
                    {
                      // 자동로그인 옵션을 사용하고, 생체인증 사용자가 로그아웃한 경우가 아닌 경우에 자동로그인 체크박스 출력
                      config.autoLogin &&
                        !(
                          sessionStorage.getItem('logout') === 'true' &&
                          localStorage.getItem('bioAuth') === 'true'
                        ) && (
                          <Form.Item name="autoLogin" noStyle>
                            <Checkbox
                              checked={checked}
                              onChange={(e) => setChecked(e.target.checked)}
                            >
                              {locale.resource['txt.auto-login']}
                            </Checkbox>
                          </Form.Item>
                        )
                    }
                    {config.saveId && (
                      <Form.Item name="saveId" noStyle valuePropName="checked" initialValue={false}>
                        <Checkbox>{locale.resource['txt.save-id']}</Checkbox>
                      </Form.Item>
                    )}
                  </div>
                  <Form.Item style={{ marginTop: 24 }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      block
                      style={styles.formHeight}
                      loading={btnLoading}
                    >
                      LOGIN
                    </Button>
                  </Form.Item>
                </Form>
              </>
            )}
          </Col>
          <Col
            span={24}
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
          >
            <div>
              <Button type="link" style={{ padding: 0 }} onClick={hanbankPasswordReset}>
                {locale.resource['config.features.custom-redirect.password-reset']}
              </Button>
              <ResetPassword resetPwdProps={resetPwdProps} setResetPwdProps={setResetPwdProps} />
            </div>
            <div>
              <Button onClick={() => setDownloadModalVisible(true)}>
                {locale.resource['txt.download-client']}
              </Button>
              <Modal
                title={locale.resource['txt.download-client']}
                centered
                width={720}
                visible={downloadModalVisible}
                onOk={() => setDownloadModalVisible(false)}
                onCancel={() => setDownloadModalVisible(false)}
                destroyOnClose
                footer={[
                  <Button key="ok" onClick={() => setDownloadModalVisible(false)}>
                    {locale.resource['txt.ok']}
                  </Button>,
                ]}
              >
                <DownloadTable size="small" locale={locale} />
              </Modal>
            </div>
          </Col>
        </Row>
      </StyledBox>
      <div style={{ marginTop: 16 }}>
        <AppCopyright />
      </div>
    </StyledBackground>
  );
}

export default Login;
