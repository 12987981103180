/**
 * 앱 커스텀 설정
 *   - 구조
 *     - antd: antd의 less 변수 커스텀(변경시 node 재시작 필요함), https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less
 *     - preference: antd 외 디자인에 사용된 스타일로 src/store/ThemeContext을 통해 앱 내에서 theme로 할당하여 사용 됨
 *     - menu: 메뉴 show/hide
 *   - 유의사항
 *     - antd 커스텀이 node에서 craco로 처리되기 때문에 import 사용 못함(require 사용)
 *     - src/store/ThemeContext에서 React의 context로 사용되기 때문에 본 파일은 src/ 폴더 밖에 위치하면 안됨(= Relative imports outside of src/ are not supported.)
 *     - 💥 값을 Spread Operator 혹은 Object.assign 으로 세팅하면 빌드시 에러가 발생한다.(참고: hanabank.failover)
 */

// FIXME antd 컬러나 preference의 경우 사이트 별로 동일할 수 있다 따라서 config의 preid가 사이트 명이 아닌 옵션별로 입력되어야할듯 ?...
// 일단 땜빵

const defaultColors = {
  '@text-color': '#5a6474',
  '@font-family': 'SpoqaHanSansNeo, sans-serif',
  // '@font-size-base': '12px', antd.compact.less를 제거하면 12px로 설정하는게 적절함
  '@layout-body-background': '#ffffff',
  '@layout-header-background': '#ffffff',
  '@layout-header-padding': '0 40px',
  '@layout-header-color': '@text-color',
  '@layout-sider-background-light': '#f8f8f9',
  '@layout-trigger-background-light': '#f8f8f9',
};

const defaultPreference = {
  appName: 'VD Portal',
  logo: 'logo.svg',
  copyright: {
    type: 'string',
    value: 'ⓒ SOMANSA',
  },
  serviceGuide: {
    text: 'txt.services-guide',
    link: 'VDI_Manual.pdf',
    hide: false,
  },
  customUrls: { passwordChange: 'default' },
};

const settings = {
  antd: {
    default: defaultColors,
    lgd: {
      '@primary-color': '#c00c3f',
    },
    lgeCWC: {
      '@primary-color': '#a50034',
    },
    lgensol: {
      '@primary-color': '#a50034',
    },
  },
  preference: {
    default: defaultPreference,
    lgd: {
      logo: 'logo_lgd.png',
      copyright: {
        type: 'image',
        value: 'copyright_lgd.png',
      },
      serviceGuide: {
        text: 'txt.services-guide',
        link: 'VDI_Manual_LGD.pdf',
        hide: false,
      },
      customUrls: {
        signUp: { windowFeatures: 'width=500,height=800,left=200,top=200,resizable=yes' },
        passwordReset: { windowFeatures: 'width=500,height=800,left=200,top=200,resizable=yes' },
      },
    },
    lgeCWC: {
      logo: 'logo_lgeCWC.png',
      copyright: {
        type: 'string',
        value: null,
      },
    },
    lgensol: {
      logo: 'logo_lgensol.png',
    },
    hanabank: {
      logo: 'logo_hanabank.svg',
      copyright: {
        type: 'string',
        value: null,
      },
      customUrls: {
        passwordReset: { windowFeatures: 'width=1024,height=590,resizable=yes' },
        passwordChange: { windowFeatures: 'width=1024,height=590,resizable=yes' },
      },
      updateCheck: true,
    },
    'hanabank-dev': {
      logo: 'logo_hanabank-dev.svg',
      copyright: {
        type: 'string',
        value: null,
      },
      customUrls: {
        passwordReset: { windowFeatures: 'width=1024,height=590,resizable=yes' },
        passwordChange: { windowFeatures: 'width=1024,height=590,resizable=yes' },
      },
      updateCheck: true,
    },
    hanacard: {
      customUrls: {
        passwordReset: { windowFeatures: 'width=1024,height=590,resizable=yes' },
        passwordChange: { windowFeatures: 'width=1024,height=590,resizable=yes' },
      },
      updateCheck: true,
    },
    meritz: {
      logo: 'logo_meritz.png',
      copyright: {
        type: 'meritz', // TODO: #189874 임시 조치, 향후 setting.js 제거할 때 확인 필요
      },
    },
    woorifis: {
      updateCheck: true,
    },
    seoulshinbo: {
      updateCheck: true,
    },
    kiat: defaultPreference,
    genohco: defaultPreference,
    steco: defaultPreference,
  },
  // 서비스 신청(request)은 vsmgmt 환경 설정으로 동작함
  hideMenu: {
    default: [],
    hanabank: ['transfer'],
    'hanabank-dev': ['transfer'],
    hanacard: ['transfer'],
    lgd: [],
    lgeCWC: [],
    lge: ['transfer'],
    meritz: ['transfer'],
    'vdi-idc': ['transfer'],
    woorifis: ['transfer'],
    seoulshinbo: ['transfer'],
    kiat: ['transfer'],
    lts: ['transfer'],
    lgensol: ['transfer'],
    genohco: [],
    steco: [],
  },
  hideLoginMenu: {
    default: [],
    hanabank: ['signup', 'guide'],
    'hanabank-dev': ['signup', 'guide'],
    hanacard: ['signup', 'guide'],
    lgd: ['signup'],
    lgeCWC: ['signup'],
    lge: ['signup'],
    meritz: [],
    'vdi-idc': [],
    woorifis: ['signup', 'guide'],
    seoulshinbo: ['signup'],
    kiat: [],
    lts: [],
    lgensol: ['signup'],
    genohco: [],
    steco: [],
  },
  redirectQuery: {
    default: false,
    hanabank: true,
    'hanabank-dev': true,
    hanacard: true,
    lgd: false,
    lgeCWC: false,
    lge: false,
    meritz: false,
    'vdi-idc': [],
    woorifis: true,
    seoulshinbo: true,
    kiat: [],
    lts: [],
    lgensol: [],
    genohco: [],
    steco: [],
  },
};

module.exports = {
  settings,
};
