/* eslint-disable react/jsx-props-no-spreading */
import { Dropdown as AntDropdown } from 'antd';

import React from 'react';
import { isTouchDevice } from 'utils';

/**
 * Antd Dropdown 컴포넌트를 래핑한 커스텀 Dropdown 컴포넌트
 * 터치 디바이스 여부에 따라 자동으로 trigger 방식이 변경됨
 * - Desktop: hover 방식으로 동작
 * - 모바일, 태블릿: click 방식으로 동작
 *
 * @param {Object} props - Dropdown 컴포넌트 props
 * @param {React.ReactNode} props.children
 * @param {string[]} [props.trigger] - 기본 trigger 설정을 덮어쓸 수 있는 옵션
 * @returns {React.ReactElement} 반응형으로 동작하는 Dropdown 컴포넌트
 */
const Dropdown = ({ children, ...props }) => {
  const defaultProps = {
    trigger: isTouchDevice() ? ['click'] : ['hover'],
  };
  return (
    <AntDropdown {...defaultProps} {...props}>
      {children}
    </AntDropdown>
  );
};

export default Dropdown;
