import { Alert, Button, Checkbox, Col, Form, Input, Modal, Row, Space } from 'antd';
import { AppCopyright, AppLogo, Icon } from 'components';
import { LocaleChanger } from 'pages/components';
import { DownloadTable } from 'pages/help/Download';
// import { localeKeyToString } from 'pages/utils';
import React, { useState } from 'react';
import { AiOutlineLock, AiOutlineUser } from 'react-icons/ai';

import styled from 'styled-components';
import { useLogin } from 'hooks/useLogin';
import ResetPassword from '../../../pages/auth/ResetPassword';

const styles = {
  formHeight: {
    height: 40,
  },
};

const StyledBackground = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  .loginBox {
    margin: 0 auto;
    padding: 40px;
    border-radius: 8px;
    background-color: #ffffff;
    box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.07), 0 3px 4px -1px rgba(0, 0, 0, 0.07),
      0 4px 9px 2px rgba(0, 0, 0, 0.07);
  }
`;

const StyledBox = styled.div`
  margin: 0 auto;
  width: 368px;
  padding: 40px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 1px 1px 1px rgba(0, 0, 0, 0.07), 0 3px 4px -1px rgba(0, 0, 0, 0.07),
    0 4px 9px 2px rgba(0, 0, 0, 0.07);
`;

function Login({ history, location }) {
  // states
  const [downloadModalVisible, setDownloadModalVisible] = useState(false);
  const [serviceGuideLink, setServiceGuideLink] = useState(null);
  const [checked, setChecked] = useState(false);

  const [resetPwdProps, setResetPwdProps] = useState({ visible: false }); // 비밀번호 변경 컴포넌트 props

  const { login, ui, settings } = useLogin({
    setResetPwdProps,
    setServiceGuideLink,
    checked,
    history,
    location,
  });
  const { loginForm, customUrls, credential, handleLoginSuccess, onFinishApi } = login;
  const { doPasswordReset, btnLoading, hideMenus, theme } = ui;
  const { config, locale, error } = settings;

  const { LoginExtraForms, MultiFactor } = config;

  // TODO 로그인페이지 로직 분리시 제거
  return (
    <StyledBackground>
      <div style={{ position: 'absolute', top: '1em', right: '1em' }}>
        <LocaleChanger />
      </div>
      <StyledBox style={config.loginBoxStyles}>
        <Row gutter={[0, 32]}>
          <Col span={24} style={{ textAlign: 'center' }}>
            <AppLogo />
          </Col>
          <Col span={24}>
            {/* startsWith('require') = require | require-secret-key */}
            {Object.prototype.hasOwnProperty.call(credential, 'twoFactor') &&
            credential.twoFactor.type.startsWith('require') ? (
              <MultiFactor credential={credential} setCredential={handleLoginSuccess} />
            ) : (
              <>
                {error && (
                  <Alert
                    type="error"
                    showIcon
                    style={{ marginBottom: 24 }}
                    message={<div style={{ whiteSpace: 'pre-line' }}>{error}</div>}
                  />
                )}
                <Form onFinish={onFinishApi} layout="vertical" form={loginForm}>
                  <Form.Item
                    name="userId"
                    rules={[{ required: true, message: locale.resource['txt.enter-id'] }]}
                  >
                    <Input
                      prefix={<Icon name={<AiOutlineUser focusable={false} />} />}
                      placeholder={locale.resource['txt.login-id']}
                      style={styles.formHeight}
                      autoFocus
                      autoComplete="username"
                    />
                  </Form.Item>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: locale.resource['txt.enter-password'],
                      },
                    ]}
                  >
                    <Input.Password
                      visibilityToggle={false}
                      allowClear
                      prefix={<Icon name={<AiOutlineLock focusable={false} />} />}
                      placeholder={locale.resource['txt.login-password']}
                      style={styles.formHeight}
                      autoComplete="current-password"
                    />
                  </Form.Item>
                  {LoginExtraForms && <LoginExtraForms />}
                  <div style={{ marginBlock: 16 }}>
                    {
                      // 자동로그인 옵션을 사용하고, 생체인증 사용자가 로그아웃한 경우가 아닌 경우에 자동로그인 체크박스 출력
                      config.autoLogin &&
                        !(
                          sessionStorage.getItem('logout') === 'true' &&
                          localStorage.getItem('bioAuth') === 'true'
                        ) && (
                          <Form.Item name="autoLogin" noStyle>
                            <Checkbox
                              checked={checked}
                              onChange={(e) => setChecked(e.target.checked)}
                            >
                              {locale.resource['txt.auto-login']}
                            </Checkbox>
                          </Form.Item>
                        )
                    }
                    {config.saveId && (
                      <Form.Item name="saveId" noStyle valuePropName="checked" initialValue={false}>
                        <Checkbox>{locale.resource['txt.save-id']}</Checkbox>
                      </Form.Item>
                    )}
                  </div>
                  <Form.Item style={{ marginTop: 24 }}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      block
                      style={styles.formHeight}
                      loading={btnLoading}
                    >
                      LOGIN
                    </Button>
                  </Form.Item>
                </Form>
              </>
            )}
          </Col>
          <Col span={24} style={{ textAlign: 'center' }}>
            <Row gutter={[0, 8]}>
              <Col span={24} style={{ textAlign: 'center' }}>
                <Space>
                  {!hideMenus.includes('signup') && customUrls?.signUp && (
                    <Button
                      type="link"
                      onClick={() => {
                        window.open(
                          customUrls?.signUp,
                          '',
                          `${
                            theme.customUrls?.signUp?.windowFeatures !== undefined
                              ? theme.customUrls.signUp.windowFeatures
                              : `height=${window.screen.height},width=${window.screen.width},resizable=yes,fullscreen=yes`
                          }`,
                        );
                      }}
                    >
                      {locale.resource['txt.register-id']}
                    </Button>
                  )}
                  <div>
                    <Button type="link" style={{ padding: 0 }} onClick={doPasswordReset}>
                      {locale.resource['config.features.custom-redirect.password-reset']}
                    </Button>
                    <ResetPassword
                      resetPwdProps={resetPwdProps}
                      setResetPwdProps={setResetPwdProps}
                    />
                  </div>
                </Space>
              </Col>
              <Col span={24} style={{ textAlign: 'center' }}>
                <Space>
                  {!hideMenus.includes('guide') && (
                    <a href={serviceGuideLink} download className="ant-btn">
                      {locale.resource[theme.serviceGuide.text]}
                    </a>
                  )}
                  {!hideMenus.includes('fileDownload') && (
                    <>
                      <Button onClick={() => setDownloadModalVisible(true)}>
                        {locale.resource['txt.download-client']}
                      </Button>
                      <Modal
                        title={locale.resource['txt.download-client']}
                        centered
                        width={720}
                        visible={downloadModalVisible}
                        onOk={() => setDownloadModalVisible(false)}
                        onCancel={() => setDownloadModalVisible(false)}
                        destroyOnClose
                        footer={[
                          <Button key="ok" onClick={() => setDownloadModalVisible(false)}>
                            {locale.resource['txt.ok']}
                          </Button>,
                        ]}
                      >
                        <DownloadTable size="small" locale={locale} />
                      </Modal>
                    </>
                  )}
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      </StyledBox>
      <div style={{ marginTop: 16 }}>
        <AppCopyright />
      </div>
    </StyledBackground>
  );
}

export default Login;
